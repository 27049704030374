import { makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import qs from 'qs'
import api from '../../api'
import { localStorage, parseDateValue, normalizationUrl } from '../../utils'

const DEFAULT_FILTER = {
  date: 'today',
}

class ReportsStore {
  reportStats = {}
  reports = []
  filter = localStorage.getObject('report.filter') || DEFAULT_FILTER
  state = 'pending' // 'pending', 'done' or 'error'
  resourceTableState = 'pending'

  reportType = 'widget'
  reportId = null
  reportDetail = []
  reportDetailStats = {}
  reportDetailList = []
  resourceDetailTableState = 'pending'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  updateUrl() {
    const params = qs.stringify(this.filter)
    const { pathname } = normalizationUrl()
    const url = `/#/${pathname}${params ? `?${params}` : ''}`
    window.history.pushState({}, '', url)
  }

  setFilter(filter) {
    localStorage.setObject('report.filter', filter)
    this.filter = filter
    this.fetchTotal()
    this.fetchReports()
    this.updateUrl()
  }

  setDetailFilter(filter) {
    localStorage.setObject('report.filter', filter)
    this.filter = filter
    this.fetchReportDetail()
    this.fetchReportDetailResource()
    this.updateUrl()
  }

  setReportId(id) {
    this.reportId = id
  }

  setReportType(type) {
    this.reportType = type
  }

  *fetchTotal() {
    this.state = 'pending'

    let params = parseDateValue(this.filter)

    try {
      let {
        data: { data },
      } = yield api.reports.getTotal(params)

      if (data.length > 0) {
        this.reportStats = data.reduce((result, item) => {
          result.cost = (result.cost || 0) + item.cost
          result.revenue = (result.revenue || 0) + item.revenue
          result.net = result.revenue ? result.revenue - result.cost : 0
          result.roi = result.revenue
            ? ((result.revenue - result.cost) / result.cost) * 100
            : 0

          return result
        }, {})
      }

      this.state = 'done'
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchReports() {
    this.resourceTableState = 'pending'

    let params = parseDateValue(this.filter)

    try {
      let {
        data: { data },
      } = yield api.reports.getReportStats(params)

      this.reports = data.map((item) => ({
        ...item,
        key: uuid(),
      }))

      this.resourceTableState = 'done'
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchReportDetail() {
    this.state = 'pending'

    let params = parseDateValue(this.filter)

    try {
      let {
        data: { data },
      } = yield api.reports.getReportStatsById({
        ...params,
        id: this.reportId,
      })

      this.reportDetail = data

      if (data.length > 0) {
        this.reportDetailStats = data.reduce((result, item) => {
          result.cost = (result.cost || 0) + item.cost
          result.revenue = (result.revenue || 0) + item.revenue
          result.net = result.revenue ? result.revenue - result.cost : 0
          result.roi = result.revenue
            ? ((result.revenue - result.cost) / result.cost) * 100
            : 0

          return result
        }, {})
      }

      this.state = 'done'
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchReportDetailResource() {
    this.resourceDetailTableState = 'pending'

    let params = parseDateValue(this.filter)
    const request =
      this.reportType === 'widget'
        ? api.reports.getWidgetsById
        : this.reportType === 'section'
        ? api.reports.getSectionsById
        : api.reports.getContentsById

    try {
      let {
        data: { data },
      } = yield request({ ...params, id: this.reportId })

      this.reportDetailList = data.map((item) => ({
        ...item,
        key: uuid(),
      }))

      this.resourceDetailTableState = 'done'
    } catch (e) {
      this.resourceDetailTableState = 'error'
      console.log('Some thing went wrong')
    }
  }
}

export default new ReportsStore()
