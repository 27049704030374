export default {
  set: (key, value) => window.localStorage.setItem(key, value),

  setObject: (key, value) =>
    window.localStorage.setItem(key, JSON.stringify(value)),

  get: (key) => window.localStorage.getItem(key),

  getObject: (key) => {
    try {
      const val = window.localStorage.getItem(key)
      return JSON.parse(val)
    } catch (e) {
      return {}
    }
  },

  removeItem: (key) => window.localStorage.removeItem(key),

  clear() {
    window.localStorage.clear()
  },
}
