import accounts from './accounts'
import campaigns from './campaigns'
import campaignsManagement from './campaignsManagement'
import newProject from './newProject'
import admin from './admin'

export default {
  accounts,
  admin,
  campaigns,
  campaignsManagement,
  newProject,
}
