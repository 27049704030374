import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './containers/App'
import { Auth0ProviderWithHistory, GlobalDataProvider } from './components'
import 'antd/dist/antd.css'
import './assets/scss/main.scss'

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <Auth0ProviderWithHistory>
        <GlobalDataProvider>
          <App />
        </GlobalDataProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
