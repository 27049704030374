import { observer } from 'mobx-react-lite'
import { Col, DatePicker, Modal, Radio, Row, Space, Table } from 'antd'
import currency from 'currency.js'
import { blue } from '@ant-design/colors'
import { useEffect, useState } from 'react'
import { CampaignsStore } from '../../../stores'
import { sortByNumber } from '../../../utils'
import parseFilterValue from '../../../utils/parseDateValue'

const { Column } = Table
const { RangePicker } = DatePicker

const FILTER_OPTIONS = [
  { label: 'Last 7 days', value: 'week' },
  { label: 'Last 30 days', value: 'month' },
]

function KeywordDrillDownModal(props) {
  const { visible, onCancel, data } = props
  const { filter, fetchCampaignKeywordDrillDownData } = CampaignsStore
  const [loading, setLoading] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [date, setDate] = useState('week')
  const [dateRange, setDateRange] = useState(null)

  useEffect(() => {
    if (visible) {
      fetchData({ date })
    } else {
      setDataTable([])
      setDate('week')
      setDateRange()
    }
    // eslint-disable-next-line
  }, [visible])

  async function fetchData(dateFilter) {
    const { from, to } = parseFilterValue(dateFilter)
    const campaignID = data?.campaignid

    setLoading(true)
    const resp = await fetchCampaignKeywordDrillDownData({
      from,
      to,
      network: filter.network,
      campaignID,
    })

    const _dataTable = resp.data.map((item, index) => ({
      ...item,
      key: index,
    }))

    setLoading(false)
    setDataTable(_dataTable)
  }

  const handleChangeDateType = ({ target: { value } }) => {
    setDate(value)
    fetchData({ date: value })
  }

  const handleChangeDateRange = (val) => {
    if (val) {
      const [from, to] = val
      setDateRange(val)
      setDate()
      fetchData({
        date: 'custom',
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      })
    } else {
      setDateRange()
      setDate('week')
    }
  }

  return (
    <Modal
      title={data?.name}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1200}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space>
            <Radio.Group
              options={FILTER_OPTIONS}
              onChange={handleChangeDateType}
              value={date}
              optionType="button"
            />

            <RangePicker value={dateRange} onChange={handleChangeDateRange} />
          </Space>
        </Col>

        <Col span={24}>
          <Table
            size="small"
            dataSource={dataTable}
            loading={loading}
            scroll={{
              x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
              y: 500,
            }}
            pagination={{
              defaultPageSize: 50,
            }}
          >
            <Column
              width={120}
              title="Keyword"
              dataIndex="keyword"
              fixed="left"
            />
            <Column
              width={80}
              title="Tonic Revenue"
              dataIndex="revenue"
              sorter={(a, b) => sortByNumber(a, b, 'revenue')}
              render={(item) => currency(item).format()}
            />
            <Column
              width={80}
              title="Tonic Total Clicks"
              dataIndex="tr_clicks"
            />
            <Column
              width={80}
              title="Keywords RPC"
              dataIndex="rpc"
              render={(item) => (
                <span style={{ color: blue[4] }}>
                  {currency(item, { precision: 3 }).format()}
                </span>
              )}
              sorter={(a, b) => sortByNumber(a, b, 'rpc')}
            />
          </Table>
        </Col>
      </Row>
    </Modal>
  )
}

export default observer(KeywordDrillDownModal)
