import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Button
      type="primary"
      icon={<GoogleOutlined />}
      onClick={() => loginWithRedirect()}
    >
      LogIn
    </Button>
  )
}

export default LoginButton
