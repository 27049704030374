import { makeAutoObservable } from 'mobx'
import { message } from 'antd'
import { v4 as uuid } from 'uuid'
import qs from 'qs'
import set from 'lodash/set'
import findIndex from 'lodash/findIndex'
import clone from 'lodash/clone'
import { AccountsStore } from '..'
import api from '../../api'
import { localStorage, parseDateValue, normalizationUrl } from '../../utils'

const DEFAULT_FILTER = {
  date: 'today',
}

class CampaignsStore {
  campaignStats = {}
  campaigns = []
  adgroupList = []
  adsList = []
  filter = this.getDefaultFilter()
  state = 'pending' // 'pending', 'done' or 'error'
  resourceTableState = 'pending'
  campaignType = 'widget'
  campaignId = null
  campaignDetail = []
  campaignDetailStats = {}
  campaignDetailList = []
  resourceDetailTableState = 'pending'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  getDefaultFilter() {
    const filter = localStorage.getObject('campaign.filter')
    return filter && Object.keys(filter).length > 0 ? filter : DEFAULT_FILTER
  }

  updateUrl() {
    const params = qs.stringify(this.filter, { arrayFormat: 'comma' })
    const { pathname } = normalizationUrl()
    const url = `/#/${pathname}${params ? `?${params}` : ''}`
    window.history.pushState({}, '', url)
  }

  setFilter(filter) {
    localStorage.setObject('campaign.filter', filter)
    this.filter = filter
    this.fetchTotal()
    this.fetchCampaigns()
    this.updateUrl()
    AccountsStore.fetchAccounts(this.filter.network)
  }

  setDetailFilter(filter) {
    localStorage.setObject('campaign.filter', filter)
    this.filter = filter
    this.fetchCampaignDetail()
    this.fetchCampaignDetailResource()
    this.updateUrl()
    AccountsStore.fetchAccounts(this.filter.network)
  }

  setCampaignId(id) {
    this.campaignId = id
  }

  setCampaignType(type) {
    this.campaignType = type
  }

  getAdGroupListParams() {
    return this.adgroupList
  }

  *fetchTotal() {
    this.state = 'pending'
    this.campaignStats = {}
    let params = parseDateValue(this.filter)

    try {
      let {
        data: { data },
      } = yield api.campaigns.getTotal(params)

      if (data.length > 0) {
        this.campaignStats = data.reduce((result, item) => {
          result.cost = (result.cost || 0) + item.cost
          result.revenue = (result.revenue || 0) + item.revenue
          result.net = result.revenue ? result.revenue - result.cost : 0
          result.roi = result.revenue
            ? ((result.revenue - result.cost) / result.cost) * 100
            : 0

          return result
        }, {})
      }

      this.state = 'done'
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchCampaigns() {
    // this.resourceTableState = 'pending'
    let params = parseDateValue(this.filter)
    try {
      let {
        data: { data },
      } = yield api.campaigns.getCampaignStats(params)
      this.campaigns = data.map((item) => ({
        ...item,
        key: item.campaignid,
      }))
      this.resourceTableState = 'done'
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Something went wrong fetch campaigns')
    }
  }

  *fetchCampaignDetail() {
    this.state = 'pending'

    let params = parseDateValue(this.filter)
    try {
      let {
        data: { data },
      } = yield api.campaigns.getCampaignStatsById({
        ...params,
        campaignID: this.campaignId,
      })

      this.campaignDetail = data

      if (data.length > 0) {
        this.campaignDetailStats = data.reduce((result, item) => {
          result.cost = (result.cost || 0) + item.cost
          result.revenue = (result.revenue || 0) + item.revenue
          result.net = result.revenue ? result.revenue - result.cost : 0
          result.roi = result.revenue
            ? ((result.revenue - result.cost) / result.cost) * 100
            : 0

          return result
        }, {})
      }

      this.state = 'done'
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchCampaignDetailResource() {
    this.resourceDetailTableState = 'pending'

    let params = parseDateValue(this.filter)
    let request =
      this.campaignType === 'widget'
        ? api.campaigns.getWidgetsById
        : this.campaignType === 'section'
        ? api.campaigns.getSectionsById
        : api.campaigns.getContentsById

    if (this.filter.network === 'taboola' && this.campaignType === 'widget') {
      request = api.campaigns.getWidgetsByIdV2
    }

    try {
      let {
        data: { data },
      } = yield request({ ...params, id: this.campaignId })

      this.campaignDetailList = data.map((item) => ({
        ...item,
        key: uuid(),
      }))

      this.resourceDetailTableState = 'done'
    } catch (e) {
      this.resourceDetailTableState = 'error'
      console.log('Some thing went wrong')
    }
  }

  async updateCampaignById({ body }) {
    try {
      // if (body.field !== 'budget') {
      //   this.resourceTableState = 'pending'
      // }
      await api.campaigns.updateCampaignById({
        body,
        network: this.filter.network,
      })
      // if (body.field !== 'budget') {
      //   this.resourceTableState = 'done'
      // }
    } catch (error) {
      this.resourceTableState = 'error'
      message.error('Something went wrong ' + error)
      console.log('error campaign store')
      console.log(error)
      return
    }
    message.success(`${body.name} ${body.field} updated successfully`)
  }

  *fetchAdgroupList(options) {
    this.resourceTableState = 'pending'
    let params = parseDateValue(this.filter)
    try {
      let {
        data: { data },
      } = yield api.campaigns.getAdgroup({
        ...options,
        ...params,
      })
      console.log('AD GROUPS:')
      console.log({ data })
      this.adgroupList = data.map((item) => ({
        ...item,
        key: uuid(),
      }))

      this.resourceTableState = 'done'
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Something went wrong')
    }
  }

  async toggleCampaign({ body, isAdGroups }) {
    try {
      await api.campaigns.updateCampaignById({
        body,
        network: this.filter.network,
      })
      if (isAdGroups) {
        // renders ad group
        const index = findIndex(
          this.adgroupList,
          (item) => item.adgroup_id === body.item_id
        )
        this.adgroupList = set(
          clone(this.adgroupList),
          [index, 'enabled'],
          body.new_value ? 1 : 0
        )
      } else {
        // renders campaign
        const index = findIndex(
          this.campaigns,
          (item) => item.campaignid === body.item_id
        )
        this.campaigns = set(
          clone(this.campaigns),
          [index, 'enabled'],
          body.new_value ? 1 : 0
        )
      }
      message.success(`Status ${body.name} update successfully!`)
    } catch (error) {
      this.resourceTableState = 'error'
      message.error(`Something went wrong` + error)
      console.log('Something went wrong' + error)
    }
  }

  //same
  *fetchDrillDownData(options) {
    try {
      const { data } = yield api.campaigns.getCampaignDrillDown({ options })
      return data
    } catch (e) {
      console.log('Some thing went wrong')
    }
  }

  *fetchCampaignKeywordDrillDownData(options) {
    try {
      const { data } = yield api.campaigns.getCampaignKeywordDrillDown({
        options,
      })
      return data
    } catch (e) {
      console.log('Some thing went wrong')
    }
  }

  *fetchAdsList(options) {
    this.resourceTableState = 'pending'

    let params = parseDateValue(this.filter)

    try {
      let {
        data: { data },
      } = yield api.campaigns.getAds({
        ...params,
        ...options,
      })

      this.adsList = data.map((item) => ({
        ...item,
        key: uuid(),
      }))

      this.resourceTableState = 'done'
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchIsFinal(options) {
    try {
      let { data } = yield api.campaigns.getIsFinal({
        ...options,
      })
      const isFinal = data['final'] === 'yes' ? true : false
      return isFinal
    } catch (e) {
      console.log('Some thing went wrong ' + e)
    }
  }
}

export default new CampaignsStore()
