import { Button, Card, Col, Divider, Row, Space, Spin, Typography } from 'antd'
import qs from 'qs'
import { blue } from '@ant-design/colors'
import { ArrowLeftOutlined } from '@ant-design/icons'
import currency from 'currency.js'
import get from 'lodash/get'
import './campaigns.scss'
import { CampaignsStore } from '../../stores'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import CampaignDetailTable from './CampaignDetailTable'
import Filter from './Filter'
import { Link } from 'react-router-dom'

const { Title, Text } = Typography

function CampaignsDetail(props) {
  const {
    match: { params },
  } = props
  const {
    setCampaignId,
    setCampaignType,
    state,
    campaignDetailStats,
    filter,
    setDetailFilter,
    fetchCampaignDetailResource,
    campaignDetailList,
  } = CampaignsStore
  const { id, campaignType } = params
  const isLoading = state === 'pending'

  useEffect(() => {
    let _filter = qs.parse(window.location.hash.split('?')[1])

    if (_filter.account_id) {
      _filter.account_id = _filter.account_id.split(',')
    }

    _filter = Object.keys(_filter).length > 0 ? _filter : filter

    setCampaignId(id)
    setCampaignType(campaignType)
    setDetailFilter(_filter)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setCampaignType(campaignType)
    fetchCampaignDetailResource()
    // eslint-disable-next-line
  }, [campaignType])

  return (
    <div className="campaign-page">
      <Title level={5} style={{ margin: 0 }}>
        <Space>
          <Link to="/campaigns">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
          <div>
            <Text type="secondary">Campaign:</Text>{' '}
            {get(campaignDetailList, '[0].name')}
          </div>
        </Space>
      </Title>

      <Divider />

      <Filter
        {...props}
        isDetail
        filter={filter}
        onChangeFilter={setDetailFilter}
      />

      <Row gutter={[16, 64]}>
        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="danger">
                Total Cost
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(campaignDetailStats.cost).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="success">
                Total Revenue
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(campaignDetailStats.revenue).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="warning">
                Total Net
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title
                  level={5}
                  type={campaignDetailStats.net >= 0 ? 'success' : 'danger'}
                >
                  {currency(campaignDetailStats.net).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} style={{ color: blue[4] }}>
                Total ROI
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title
                  level={5}
                  type={campaignDetailStats.roi >= 0 ? 'success' : 'danger'}
                >
                  {currency(campaignDetailStats.roi).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <CampaignDetailTable {...props} />
        </Col>
      </Row>
    </div>
  )
}

export default observer(CampaignsDetail)
