import React from 'react'
import { Form } from 'antd'

export const EditableContext = React.createContext(null)

// eslint-disable-next-line
export default function EditableRow({ index, ...props }) {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
