import moment from 'moment'
import { DatePicker, Radio, Select, Space } from 'antd'
import { useEffect, useMemo, useState } from 'react'

const { RangePicker } = DatePicker

const FILTER_OPTIONS = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'week' },
]

export default function Filter(props) {
  const { filter, onChangeFilter, accounts } = props
  const [selectedAccount, setSelectedAccount] = useState()
  const [dateRange, setDateRange] = useState(null)
  const [accountOptions, setAccountOptions] = useState(null)

  useEffect(() => {
    setSelectedAccount(
      accountOptions.find((item) => item.key == filter.account)
    )

    if (filter.date === 'custom') {
      setDateRange([moment(filter.from), moment(filter.to)])
    }
    // eslint-disable-next-line
  }, [filter])

  useMemo(() => {
    const _accountOptions = accounts.map((item) => ({
      key: item.id,
      value: item.name,
    }))
    setAccountOptions(_accountOptions)

    setSelectedAccount(
      _accountOptions.find((item) => item.key == filter.account)
    )
    // eslint-disable-next-line
  }, [accounts])

  const handleChangeDateType = ({ target: { value } }) => {
    onChangeFilter({
      account: filter.account ? filter.account : undefined,
      date: value,
    })
  }

  const handleChangeDateRange = (val) => {
    if (val) {
      const [from, to] = val
      onChangeFilter({
        account: filter.account ? filter.account : undefined,
        date: 'custom',
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      })
    } else {
      setDateRange()
      onChangeFilter({
        account: filter.account ? filter.account : undefined,
        date: 'today',
      })
    }
  }

  const handleChangeAccount = (val, data) => {
    if (val) {
      onChangeFilter({
        ...filter,
        account: data.key,
      })
    } else {
      const { account, ...rest } = filter // eslint-disable-line
      onChangeFilter(rest)
    }
  }

  return (
    <div className="filter-section">
      <Space>
        <Radio.Group
          options={FILTER_OPTIONS}
          onChange={handleChangeDateType}
          value={filter.date || 'today'}
          optionType="button"
        />
        <RangePicker value={dateRange} onChange={handleChangeDateRange} />
        <Select
          showSearch
          allowClear
          placeholder="Select an account"
          options={accountOptions}
          style={{ width: 250 }}
          value={selectedAccount}
          onChange={handleChangeAccount}
        />
      </Space>
    </div>
  )
}
