import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Filter from '../Campaigns/Filter'
import TotalDataPanel from './TotalDataPanel'
import NetworkPreformPanel from './NetworkPreformPanel'
import MediaBuyerPreformPanel from './MediaBuyerPreformPanel/index'
import { AdminStore } from '../../stores'
import './Admin.scss'

function Admin(props) {
  const { setFilter, filter } = AdminStore
  const [isAdmin] = useState(true)
  const [rangeDateChanged, setRangeDateChanged] = useState(false)

  return (
    <div className="admin-page">
      <div className="admin-filter">
        <Filter
          {...props}
          filter={filter}
          isAdmin={isAdmin}
          onChangeFilter={setFilter}
          setRangeDateChanged={setRangeDateChanged}
        />
      </div>
      <TotalDataPanel filter={filter} />
      <NetworkPreformPanel {...props} filter={filter} />
      <MediaBuyerPreformPanel
        {...props}
        rangeDateChanged={rangeDateChanged}
        setRangeDateChanged={setRangeDateChanged}
      />
    </div>
  )
}

export default observer(Admin)
