import { Typography, Col, Row, Button, Space, Image } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useCallback } from 'react'
import currency from 'currency.js'
import { AdminStore } from '../../../stores'
import { localStorage, sortByNumber, sortByString } from '../../../utils'
import DrilldownDataModal from '../../Campaigns/DrilldownDataModal'
import {
  Table,
  EditableRow,
  EditableCell,
  useGlobalData,
} from '../../../components'

import { TRAFFIC_SOURCE_TYPES } from '../../../assets/TrafficSourceTypes'
import './NetworkPreformPanel.scss'

const { Column } = Table
const { Title } = Typography
const TOTAL_DATA_PER_NETWORK = 'spendbynetworkaggregate'

function NetworkPreformPanel() {
  const { networks } = useGlobalData()
  const [drillDownModal, setDrillDownModal] = useState(null)
  const [listItems, setListItems] = useState([])
  const { filter, resourceTableState, fetchTotalDataPerNetwork } = AdminStore
  const trafficSourceTypes = TRAFFIC_SOURCE_TYPES.filter((item) =>
    networks.includes(item.value)
  )

  const getTotalDataPerNetwork = useCallback(async () => {
    const date = localStorage.getObject('campaign.filter')
    const data = await fetchTotalDataPerNetwork({
      from: date.from,
      to: date.to,
      reportName: TOTAL_DATA_PER_NETWORK,
    })

    const totalDataPerNetworkList = data.map((item) => ({
      ...item,
    }))

    setListItems(totalDataPerNetworkList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    getTotalDataPerNetwork()
    // eslint-disable-next-line
  }, [getTotalDataPerNetwork])

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Title level={5} className="network-title">
            Performance by Network
          </Title>
          <Table
            size="small"
            dataSource={listItems}
            components={components}
            loading={resourceTableState === 'pending'}
            scroll={{
              x: 'max-content', //original: 1500 === 'max-content' === 'fit-content'
              y: '55vh',
            }}
            resizeColumn
          >
            <Column
              ellipsis
              width={100}
              title="Name"
              dataIndex="network"
              sorter={(a, b) => sortByString(a, b, 'name')}
              render={(item, row, i = 0) => {
                return (
                  <Space className="row-actions">
                    <span className="row-actions-text">
                      <Space>
                        {trafficSourceTypes[i].image && (
                          <Image
                            src={
                              trafficSourceTypes?.filter(
                                (network) => item === network.value
                              )[0].image
                            }
                            preview={false}
                            height={20}
                            width={20}
                          />
                        )}

                        <span className="text">{item}</span>
                      </Space>
                    </span>

                    <Button
                      icon={<AppstoreOutlined />}
                      size="small"
                      onClick={() => setDrillDownModal(row)}
                    />
                  </Space>
                )
              }}
              fixed="left"
            />

            <Column
              width={120}
              title="Cost"
              dataIndex="spend"
              sorter={(a, b) => sortByNumber(a, b, 'spend')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />
            <Column
              ellipsis
              width={100}
              title="Revenue"
              dataIndex="revenue"
              sorter={(a, b) => sortByNumber(a, b, 'revenue')}
              render={(item) => currency(item).format()}
            />
            <Column
              ellipsis
              width={100}
              title="NET"
              dataIndex="net_profit"
              sorter={(a, b) => sortByNumber(a, b, 'net_profit')}
              render={(item) => currency(item).format()}
            />
            <Column
              ellipsis
              width={100}
              title=" ROI"
              dataIndex="roi"
              sorter={(a, b) => sortByNumber(a, b, 'roi')}
            />
          </Table>
        </Col>
      </Row>

      <DrilldownDataModal
        data={drillDownModal}
        visible={!!drillDownModal}
        onCancel={() => setDrillDownModal(null)}
        parent="admin"
      />
    </>
  )
}

export default observer(NetworkPreformPanel)
