import React from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import { sortByNumber, sortByDate, sortByString } from '../../../utils'

export default [
  {
    id: 'acpc',
    width: 120,
    title: 'Avg CPC',
    dataIndex: 'acpc',
    sorter: (a, b) => sortByNumber(a, b, 'acpc'),
    render: (item) => (
      <span style={{ color: blue[4] }}>{currency(item).format()}</span>
    ),
  },

  {
    id: 'cpc',
    width: 120,
    title: 'CPC',
    dataIndex: 'cpc',
    render: (item) => (
      <span style={{ color: blue[4] }}>
        {currency(item, { precision: 3 }).format()}
      </span>
    ),
    sorter: (a, b) => sortByNumber(a, b, 'cpc'),
    editable: true,
  },

  {
    id: 'cpc_original',
    width: 120,
    title: 'CPC Original',
    dataIndex: '',
    render: (item, row) => row.cpc,
    sorter: (a, b) => sortByNumber(a, b, 'cpc'),
    editable: true,
  },

  {
    id: 'ctr',
    width: 120,
    title: 'CTR',
    dataIndex: 'ctr',
    sorter: (a, b) => sortByNumber(a, b),
    render: (item) => {
      const value = Math.round(item * 100) / 100 || 0
      return `${value.toLocaleString()}%`
    },
  },

  {
    id: 'rpm',
    width: 120,
    title: 'RPM',
    dataIndex: 'rpm',
    render: (item) => (
      <span style={{ color: blue[4] }}>{currency(item).format()}</span>
    ),
    sorter: (a, b) => sortByNumber(a, b, 'rpm'),
  },

  {
    id: 'revenue',
    width: 120,
    title: 'Revenue',
    dataIndex: 'revenue',
    sorter: (a, b) => sortByNumber(a, b, 'revenue'),
    render: (item) => currency(item).format(),
  },

  {
    id: 'conversions',
    width: 120,
    title: 'Conversions',
    dataIndex: 'conversions',
    sorter: (a, b) => sortByNumber(a, b, 'conversions'),
  },

  {
    id: 'created_time',
    width: 120,
    title: 'Added',
    dataIndex: 'created_time',
    sorter: (a, b) => sortByDate(a, b, 'created_time'),
  },

  {
    id: 'uniques',
    width: 120,
    title: 'Uniques',
    dataIndex: 'uniques',
    sorter: (a, b) => sortByNumber(a, b, 'uniques'),
  },

  {
    id: 'tracking_link',
    width: 130,
    title: 'Tracking Link',
    dataIndex: 'tracking_link',
    sorter: (a, b) => sortByString(a, b, 'tracking_link'),
  },

  {
    id: 'geo',
    width: 70,
    title: 'Geo',
    dataIndex: 'geo',
    sorter: (a, b) => sortByString(a, b, 'geo'),
  },

  {
    id: 'vertical',
    width: 100,
    title: 'Vertical',
    dataIndex: 'vertical',
    sorter: (a, b) => sortByString(a, b, 'vertical'),
  },

  // {
  //   id: 'section_sign',
  //   width: 50,
  //   title: '§',
  //   dataIndex: 'section_sign',
  //   sorter: (a, b) => sortByString(a, b, 'section_sign'),
  // },

  // {
  //   id: 'clicks',
  //   width: 120,
  //   title: 'Clicks',
  //   dataIndex: 'clicks',
  //   sorter: (a, b) => sortByNumber(a, b, 'clicks'),
  //   render: (item) => item.toLocaleString(),
  // },

  // {
  //   id: 'account_id',
  //   width: 120,
  //   title: 'Account ID',
  //   dataIndex: 'account_id',
  // },
  // {
  //   id: 'account_name',
  //   width: 120,
  //   title: 'Account Name',
  //   dataIndex: 'account_name',
  // },
]
