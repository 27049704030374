import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'
import { EditableContext } from './EditableRow'

export default function EditableCell(props) {
  const {
    title,
    children,
    dataIndex,
    record,
    handleSave,
    editable,
    ...restProps
  } = props
  const [editing, setEditing] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [loadingBudget, setLoadingBudget] = useState(false)
  const [masterEdit, setMasterEdit] = useState(true)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    // set master edit in case budget=0
    if (record && (record.budget == 0 || record.budget == null))
      setMasterEdit(false)
  }, [record])

  useEffect(() => {
    setIsDirty(false)
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }
  const toggleLoading = () => {
    setLoadingBudget((prevState) => !prevState)
  }
  const save = async () => {
    if (!isDirty) {
      toggleEdit()
      return null
    }
    toggleLoading()
    try {
      const values = await form.validateFields()
      toggleEdit()
      await handleSave({
        ...record,
        ...values,
      })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
    toggleLoading()
  }
  let childNode = children
  if (editable && masterEdit) {
    childNode = editing ? (
      <div className="editable-cell">
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onChange={() => setIsDirty(true)}
            style={{
              width: 180,
            }}
          />
        </Form.Item>
        <div className="buttons">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={save}
            disabled={!isDirty}
          />
          <Button icon={<CloseOutlined />} onClick={toggleEdit} />
        </div>
      </div>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={toggleEdit}
      >
        {children}
        {loadingBudget && <Spin style={{ marginLeft: 5 }} />}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}
