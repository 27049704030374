import { Table, Col, Row, Input, Space, Button, Tooltip } from 'antd'
import { ExportOutlined, CopyOutlined, ReloadOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { CampaignsManagementStore } from '../../../stores'
import DrilldownDataModal from '../DrilldownDataModal'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { sortByDate, sortByNumber, sortByString } from '../../../utils'
import { EditableRow, EditableCell, useGlobalData } from '../../../components'
import Filter from '../Filter'
import qs from 'qs'

const { Column } = Table
const { Search } = Input

function CampaignManagementTable(props) {
  const [drillDownModal, setDrillDownModal] = useState(null)
  const [tonicCampaigns, setTonicCampaigns] = useState(null)
  const [listItems, setListItems] = useState([])
  const [query, setQuery] = useState('')
  const { campaignsManagement, resourceTableState } = CampaignsManagementStore

  const { networks } = useGlobalData()
  const { setFilter, filter } = CampaignsManagementStore

  useEffect(() => {
    let _filter = qs.parse(window.location.hash.split('?')[1])

    if (_filter.account_id) {
      _filter.account_id = _filter.account_id.split(',')
    }
    _filter = Object.keys(_filter).length > 0 ? _filter : filter
    if (networks && networks.length > 0 && !filter.network) {
      setFilter({
        ..._filter,
        network: networks[0],
      })
    } else {
      setFilter(_filter)
    }
    // eslint-disable-next-line
  }, [networks])

  useEffect(() => {
    getTonicCampaigns()
  }, [])
  const getTonicCampaigns = async () => {
    const tonicCampaigns = await CampaignsManagementStore.fetchTonicCampaigns()
    const _tonicCampaigns = tonicCampaigns.map((item) => ({
      key: item.id,
      value: item.name,
    }))
    setTonicCampaigns(_tonicCampaigns)
  }

  useEffect(() => {
    setListItems(campaignsManagement)
    // eslint-disable-next-line
  }, [campaignsManagement])

  useEffect(() => {
    if (query) {
      setListItems(
        campaignsManagement.filter((item) => item.name.includes(query))
      )
    } else {
      setListItems(campaignsManagement)
    }
    // eslint-disable-next-line
  }, [query, resourceTableState])

  const onSearch = ({ target: { value } }) => setQuery(value)

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  function CopyButton({ name }) {
    const [copied, setCopied] = useState(false)

    const handleCopyTrackingUrl = () => {
      navigator.clipboard.writeText(name)
      setCopied(true)
    }

    return (
      <Tooltip placement="top" title={copied ? 'Copied' : 'Copy to clipboard'}>
        <Button
          icon={<CopyOutlined />}
          size="small"
          onClick={handleCopyTrackingUrl}
          onBlur={() => setCopied(false)}
        />
      </Tooltip>
    )
  }

  function refreshPage() {
    getTonicCampaigns()
  }

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={12} align="left">
          <Space>
            <Button icon={<ReloadOutlined />} onClick={refreshPage}></Button>
            <CSVLink
              data={listItems}
              target="_blank"
              filename="TonicCampaigns-export.csv"
            >
              <Button icon={<ExportOutlined />}>Export</Button>
            </CSVLink>
            <Search
              allowClear
              placeholder="Search by name"
              value={query}
              onChange={onSearch}
              style={{ width: 250 }}
            />
            <Filter {...props} filter={filter} onChangeFilter={setFilter} />
          </Space>
        </Col>

        <Col span={24}>
          <Table
            size="small"
            dataSource={listItems}
            components={components}
            options={tonicCampaigns}
            loading={resourceTableState === 'pending'}
            scroll={{
              x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
              y: 500,
            }}
            pagination={{
              defaultPageSize: 50,
            }}
            resizeColumn
          >
            <Column
              ellipsis
              fixed="left"
              width={150} // "auto"
              title="Campaign Name"
              dataIndex="name"
              sorter={(a, b) => sortByString(a, b, 'name')}
            />

            <Column
              width={120}
              title="Campaign Id"
              dataIndex="campaign_id"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByNumber(a, b, 'campaign_id')}
            />

            <Column
              copyable
              width={150} // "auto"
              id="trackingUrlId"
              title="Tracking Url"
              dataIndex="tracking_url"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'tracking_url')}
              render={(item) => {
                return (
                  <Space className="row-actions">
                    <CopyButton name={item} />
                    {item}
                  </Space>
                )
              }}
            />

            <Column
              width="auto"
              title="User"
              dataIndex="user"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'user')}
            />

            <Column
              width={100}
              title="Country"
              dataIndex="country"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'country')}
            />

            <Column
              width={100}
              title="Network"
              dataIndex="network_id"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'network_id')}
            />
            <Column
              width={90}
              title="Accout id"
              dataIndex="account_id"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'callback')}
            />
            <Column
              width={100}
              title="Callback"
              dataIndex="callback"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'callback')}
            />

            <Column
              width="auto"
              title="Created At"
              dataIndex="createdAt"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByDate(a, b, 'createdAt')}
              render={(item) => moment(item).format()}
            />

            <Column
              width={80}
              title="Id"
              dataIndex="id"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByNumber(a, b, 'id')}
            />

            <Column
              width="auto" //{120}
              title="Status"
              dataIndex="error"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'keywords')}
            />

            <Column
              width="auto"
              title="Offer"
              dataIndex="offer"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'offer')}
            />

            <Column
              width="auto"
              title="Status"
              dataIndex="status"
              sorter={(a, b) => sortByString(a, b, 'status')}
            />

            <Column
              width="auto"
              title="Updated At"
              dataIndex="updatedAt"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByDate(a, b, 'updatedAt')}
              render={(item) => moment(item).format()}
            />
            <Column
              width="auto"
              title="Tiktok pixel"
              dataIndex="tiktok_pixel_id"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'tiktok_pixel_id')}
            />
            <Column
              width="auto"
              title="Facebook pixel"
              dataIndex="facebook_account"
              defaultSortOrder="descend"
              sorter={(a, b) => sortByString(a, b, 'facebook_account')}
            />
          </Table>
        </Col>
      </Row>
      <DrilldownDataModal
        data={drillDownModal}
        visible={!!drillDownModal}
        onCancel={() => setDrillDownModal(null)}
      />
    </>
  )
}

export default observer(CampaignManagementTable)
