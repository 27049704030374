import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'antd'
import findIndex from 'lodash/findIndex'
import classes from 'classnames'
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import './columnCustomizeModal.scss'

const Item = React.forwardRef(({ data, isDragging, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={classes('campaign-column-sortable-item', {
        dragging: isDragging,
      })}
    >
      {data.title}
    </div>
  )
})
Item.displayName = 'Item'

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      isDragging={isDragging}
      ref={setNodeRef}
      style={style}
      id={props.id}
      data={props.data}
      {...attributes}
      {...listeners}
    />
  )
}

function DroppableContainer(props) {
  const { setNodeRef } = useSortable({ id: props.id })

  return <div ref={setNodeRef}>{props.children}</div>
}

export default function ColumnCustomizeModal(props) {
  const { visible, onSubmit, onCancel } = props
  const [activeId, setActiveId] = useState(null)
  const [items, setItems] = useState(props.items)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  useEffect(() => {
    setItems(props.items)
    setActiveId(null)
    // eslint-disable-next-line
  }, [visible])

  const findContainer = (id) => {
    if (id in items) {
      return id
    }

    return Object.keys(items).find((key) => items[key].find((i) => i.id === id))
  }

  const findItem = (id) => {
    const activeContainer = findContainer(id)
    return items[activeContainer].find((item) => item.id === id)
  }

  function handleDragEnd({ active, over }) {
    const activeContainer = findContainer(active.id)

    if (!activeContainer) {
      setActiveId(null)
      return
    }

    const overId = over?.id

    if (overId == null) {
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId)

    if (overContainer) {
      const activeIndex = findIndex(items[activeContainer], { id: active.id })
      const overIndex = findIndex(items[overContainer], { id: overId })

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(
            items[overContainer],
            activeIndex,
            overIndex
          ),
        }))
      }
    }

    setActiveId(null)
  }

  function handleDragOver({ active, over }) {
    const overId = over?.id

    const overContainer = findContainer(overId)
    const activeContainer = findContainer(active.id)

    if (!overContainer || !activeContainer) {
      return
    }

    if (activeContainer !== overContainer) {
      setItems((items) => {
        const activeItems = items[activeContainer]
        const overItems = items[overContainer]
        const overIndex = findIndex(overItems, { id: overId })
        const activeIndex = findIndex(activeItems, { id: active.id })

        let newIndex

        if (overId in items) {
          newIndex = overItems.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter(
            (item) => item.id !== active.id
          ),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(
              newIndex,
              items[overContainer].length
            ),
          ],
        }
      })
    }
  }

  function handleDragStart(event) {
    const { active } = event
    setActiveId(active.id)
  }

  return (
    <Modal
      className="column-customize-modal"
      title="Column settings"
      visible={visible}
      onOk={() => onSubmit(items)}
      onCancel={onCancel}
      okText="Apply"
      width={900}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <Row gutter={[48]}>
          <Col span={12} style={{ borderRight: '1px solid #d9d9d9' }}>
            <div className="container-title">
              <strong>Column visibility</strong>
            </div>

            <DroppableContainer id="visibility">
              <SortableContext
                items={items.visibility}
                strategy={verticalListSortingStrategy}
              >
                {items.visibility.map((data) => (
                  <SortableItem key={data.dataIndex} id={data.id} data={data} />
                ))}
              </SortableContext>
            </DroppableContainer>
          </Col>

          <Col span={12} className="column-order">
            <div className="container-title">
              <strong>Column order</strong>
            </div>

            <DroppableContainer id="selected">
              <SortableContext
                items={items.selected}
                strategy={verticalListSortingStrategy}
              >
                {items.selected.map((data) => (
                  <SortableItem key={data.dataIndex} id={data.id} data={data} />
                ))}
              </SortableContext>
            </DroppableContainer>
          </Col>
        </Row>

        <DragOverlay>
          {activeId ? <Item id={activeId} data={findItem(activeId)} /> : null}
        </DragOverlay>
      </DndContext>
    </Modal>
  )
}
