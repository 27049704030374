import { useAuth0 } from '@auth0/auth0-react'
import {
  UserOutlined,
  DashboardOutlined,
  StockOutlined,
  PlusOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { Menu, Layout, Avatar, Space, Image } from 'antd'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { localStorage } from '../../utils'
import { LoginButton } from '..'
import { AdminStore } from '../../stores'
const { Sider } = Layout

export default function Sidebar() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const { isAuthenticated, user = {}, logout } = useAuth0()
  const { fetchIsAdmin } = AdminStore

  useEffect(() => {
    checkRole()
  }, [])

  useEffect(() => {
    if (localStorage.get('admin') === null && isAuthenticated) {
      getIsAdmin()
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  const getIsAdmin = async (retry = 5) => {
    while (retry > 0) {
      try {
        const data = await fetchIsAdmin()
        if (data === 'Admin') {
          localStorage.set('admin', 'true')
          checkRole()
          break
        }
        retry--
      } catch (error) {
        console.log(error)
      }
    }
  }
  const checkRole = () => {
    if (localStorage.get('admin') === 'true') {
      setIsAdmin(true)
    }
  }

  const getSelected = () => {
    const [, pathname] = location.pathname.split('/')
    return `/${pathname}`
  }

  const onClickLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
    const customField = localStorage.get('campaign.customField')
    const drilldownCustomField = localStorage.get('drilldownModal.customField')
    localStorage.clear()
    localStorage.set('campaign.customField', customField)
    localStorage.set('drilldownModal.customField', drilldownCustomField)
  }

  return (
    <Sider
      className="site-sidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="logo" style={{ padding: 16 }}>
        <Image
          src="/assets/images/logo-tarzo.png"
          height={40}
          preview={false}
        />
      </div>
      <div className="user">
        {isAuthenticated ? (
          <Space>
            <Avatar src={user.picture} />
          </Space>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <LoginButton />
          </div>
        )}
      </div>

      <Menu theme="dark" mode="inline" defaultSelectedKeys={[getSelected()]}>
        {isAdmin && (
          <Menu.Item
            key="/admin"
            icon={<UserOutlined />}
            disabled={!isAuthenticated}
          >
            <Link to="/admin">Admin</Link>
          </Menu.Item>
        )}

        <Menu.Item
          key="/campaigns"
          icon={<DashboardOutlined />}
          disabled={!isAuthenticated}
        >
          <Link to="/campaigns">Campaigns</Link>
        </Menu.Item>

        <Menu.Item
          key="/campaignsManagement"
          icon={<StockOutlined />}
          disabled={!isAuthenticated}
        >
          <Link to="/campaignsManagement">CMP</Link> {/*Management*/}
        </Menu.Item>

        <Menu.Item
          key="/newProject"
          icon={<PlusOutlined />}
          disabled={!isAuthenticated}
        >
          <Link to="/newProject">New Campaign</Link>
        </Menu.Item>

        {isAuthenticated && (
          <Menu.Item
            key="/logout"
            icon={<LogoutOutlined />}
            onClick={onClickLogout}
          >
            Logout
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}
