import React, { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import jwt_decode from 'jwt-decode'
import { localStorage } from '../../utils'

/* First we will make a new context */
const GlobalDataContext = React.createContext()
const DOMAIN = 'https://users.tarzomedia.com'

/* Then create a provider Component */
const GlobalDataProvider = (props) => {
  const { getAccessTokenSilently } = useAuth0()
  const [value, setValue] = useState({
    accounts: {},
    networks: [],
    roles: [],
  })

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently()
        localStorage.set('authentication', token)

        const decodeData = jwt_decode(token)
        const accounts = decodeData[`${DOMAIN}/accounts`]
        const roles = decodeData[`${DOMAIN}/roles`]
        let networks = accounts?.networks || []
        networks = networks
          .map((i) => i.toLowerCase())
          .filter((item) => item !== 'all')

        setValue({
          networkData: accounts,
          networks,
          roles,
        })
      } catch (e) {
        console.error(e)
      }
    })()
  }, [getAccessTokenSilently])

  return (
    <GlobalDataContext.Provider value={value}>
      {props.children}
    </GlobalDataContext.Provider>
  )
}

function useGlobalData() {
  return useContext(GlobalDataContext)
}

export default GlobalDataContext
export { GlobalDataProvider, useGlobalData }
