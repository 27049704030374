import { Card, Col, Row, Space, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import currency from 'currency.js'
import { blue } from '@ant-design/colors'

const { Title } = Typography

export default function Metadata(props) {
  const { loading, dataTable = [], isAdmin } = props

  const totalCost = useMemo(() => {
    return dataTable.reduce((result, item) => {
      result += isAdmin ? +item.spend : +item.cost
      return result
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable])

  const totalRevenue = useMemo(() => {
    return dataTable.reduce((result, item) => {
      result += +item.revenue
      return result
    }, 0)
  }, [dataTable])

  const totalNETProfit = useMemo(() => {
    return dataTable.reduce((result, item) => {
      result += +item.net_profit
      return result
    }, 0)
  }, [dataTable])

  const totalROI = useMemo(() => {
    return dataTable.reduce(
      (result, item) => {
        result.revenue += +item.revenue
        result.cost += isAdmin ? +item.spend : +item.cost
        return result
      },
      { revenue: 0, cost: 0 }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable])

  return (
    <Col span={24}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card size="small">
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Title level={5} type="danger" style={{ margin: 0 }}>
                Total Cost
              </Title>
              {loading ? (
                <Spin />
              ) : (
                <Title level={5} style={{ margin: 0 }}>
                  {currency(totalCost).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card size="small">
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Title level={5} type="success" style={{ margin: 0 }}>
                Total Revenue
              </Title>
              {loading ? (
                <Spin />
              ) : (
                <Title level={5} style={{ margin: 0 }}>
                  {currency(totalRevenue).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card size="small">
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Title level={5} type="warning" style={{ margin: 0 }}>
                Total Net
              </Title>
              {loading ? (
                <Spin />
              ) : (
                <Title
                  level={5}
                  style={{ margin: 0 }}
                  type={totalNETProfit >= 0 ? 'success' : 'danger'}
                >
                  {currency(totalNETProfit).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card size="small">
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Title level={5} style={{ margin: 0, color: blue[4] }}>
                Total ROI
              </Title>
              {loading ? (
                <Spin />
              ) : (
                <Title level={5} style={{ margin: 0 }}>
                  {Math.round((totalROI.revenue / totalROI.cost) * 100)}%
                </Title>
              )}
            </Space>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
