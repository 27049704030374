import { Select, Space, Row } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { NewProjectStore, CampaignsManagementStore } from '../../stores'

function Filter(props) {
  const { filter, onChangeFilter, isDetail } = props
  const [selectedAccount, setSelectedAccount] = useState()
  const [accountOptions, setAccountOptions] = useState(null)
  const { accounts, state } = NewProjectStore
  const isAccountLoading = state === 'pending'

  useEffect(() => {
    const getTonicCampaigns = async () => {
      await CampaignsManagementStore.fetchTonicCampaigns()
    }
    getTonicCampaigns()
  }, [])

  useEffect(() => {
    const getFetchTonicAccountFilter = async () => {
      const accounts = await NewProjectStore.fetchPlatformsAccounts('tonic')
      const _accountOptions = accounts.map((item) => ({
        key: item.account_id,
        value: item.name,
      }))
      setAccountOptions(_accountOptions)
    }
    getFetchTonicAccountFilter()

    const accountIds = filter.account_id || []
    const _selectedAccount = accountIds.map((id) =>
      accountOptions.find((item) => item?.key == id)
    )
    setSelectedAccount(_selectedAccount)
    // eslint-disable-next-line
  }, [filter])

  useMemo(() => {
    const _accountOptions = accounts.map((item) => ({
      key: item.account_id,
      value: item.name,
    }))
    setAccountOptions(_accountOptions)

    const accountIds = filter.account_id || []
    const _selectedAccount = accountIds.map((id) =>
      _accountOptions.find((item) => item.key == id)
    )
    setSelectedAccount(_selectedAccount)
    // eslint-disable-next-line
  }, [accounts])

  const handleChangeAccount = (val, data) => {
    if (data.length > 0) {
      onChangeFilter({
        ...filter,
        account_id: data.map((i) => i.key),
      })
    } else {
      const { account_id, ...rest } = filter // eslint-disable-line
      onChangeFilter(rest)
    }
  }

  return (
    <div className="filter-section">
      <Space>
        <Row gutter={[0, 24]}>
          {!isDetail && (
            <div style={{ width: 250 }}>
              <Select
                showSearch
                allowClear
                maxTagCount="responsive"
                placeholder="Select an account"
                options={accountOptions}
                style={{ width: '100%' }}
                value={selectedAccount}
                onChange={handleChangeAccount}
                loading={isAccountLoading}
                // disabled={accounts.length === 0 || isAccountLoading}
              />
            </div>
          )}
        </Row>
      </Space>
    </div>
  )
}

export default observer(Filter)
