import { makeAutoObservable } from 'mobx'
import qs from 'qs'
import { NewProjectStore } from '..'
import api from '../../api'
import { localStorage, normalizationUrl } from '../../utils'

const DEFAULT_FILTER = {
  date: 'today',
}

class CampaignsManagementStore {
  campaignStats = {}
  campaignsManagement = []
  adgroupList = []
  adsList = []
  filter = this.getDefaultFilter()
  state = 'pending' // 'pending', 'done' or 'error'
  resourceTableState = 'pending'

  campaignType = 'widget'
  campaignId = null
  campaignDetail = []
  campaignDetailStats = {}
  campaignDetailList = []
  resourceDetailTableState = 'pending'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  getDefaultFilter() {
    const filter = localStorage.getObject('campaignsManagements.filter')
    return filter && Object.keys(filter).length > 0 ? filter : DEFAULT_FILTER
  }

  updateUrl() {
    const params = qs.stringify(this.filter, { arrayFormat: 'comma' })
    const { pathname } = normalizationUrl()
    const url = `/#/${pathname}${params ? `?${params}` : ''}`
    window.history.pushState({}, '', url)
  }

  setFilter(filter) {
    localStorage.setObject('campaignsManagements.filter', filter)
    this.filter = filter
    this.updateUrl()
    this.fetchTonicCampaigns(this.filter.network)
    NewProjectStore.fetchPlatformsAccounts(this.filter.network)
  }

  setDetailFilter(filter) {
    localStorage.setObject('campaignsManagements.filter', filter)
    this.filter = filter
    this.updateUrl()
    this.fetchTonicCampaigns(this.filter.network)
    NewProjectStore.fetchPlatformsAccounts(this.filter.network)
  }

  setCampaignId(id) {
    this.campaignId = id
  }

  setCampaignType(type) {
    this.campaignType = type
  }

  // ============ getTonicCampaigns (from api/campaignsManagement) ============
  *fetchTonicCampaigns() {
    this.resourceTableState = 'pending'

    try {
      let {
        data: { data },
      } = yield api.campaignsManagement.getTonicCampaigns()
      this.campaignsManagement = data.map((item) => ({
        ...item,
        key: item.campaignid,
      }))

      this.resourceTableState = 'done'
      return data
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Some thing went wrong with fetchTonicCampaigns')
    }
  }

  // ============ getTonicCampaigns (from api/campaignsManagement) ============
  // *fetchTonicCampaigns() {
  //   this.state = 'pending'

  //   try {
  //     let {
  //       data: { data },
  //     } = yield api.campaignsManagement.getTonicCampaigns()
  //     this.state = 'done'
  //     return data
  //   } catch (e) {
  //     this.state = 'error'
  //     console.log('Some thing went wrong with fetchTonicCampaigns')
  //   }
  // }

  // *updateCampaignById({ body }) {
  //   try {
  //     this.resourceTableState = 'pending'
  //     yield api.campaigns.updateCampaignById({
  //       body,
  //       network: this.filter.network,
  //     })

  //     this.resourceTableState = 'done'
  //   } catch (e) {
  //     this.resourceTableState = 'error'
  //     console.log('Some thing went wrong with "updateCampaignById"')
  //   }
  // }

  // *fetchDrillDownData(options) {
  //   try {
  //     const { data } = yield api.campaigns.getCampaignDrillDown({ options })
  //     return data
  //   } catch (e) {
  //     console.log('Some thing went wrong with "fetchDrillDownData"')
  //   }
  // }
}

export default new CampaignsManagementStore()
