import { observer } from 'mobx-react-lite'
import { Button, Col, Form, Row, Input, Typography, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { NewProjectStore } from '../../stores'

function TiktokPixel(props) {
  const { isDetail, onCreationSuccess } = props
  const { Title } = Typography
  const [form] = Form.useForm()

  const [accountName, setAccountName] = useState('')
  const [pixelId, setPixelId] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [testToken, setTestToken] = useState('')

  //   const [showCreateTiktokPixel, setShowCreateTiktokPixel] = useState(false)

  //   const handleShowCreateTiktokPixel = async () => {
  //     setShowCreateTiktokPixel(true)
  //   }

  const handleAccountName = (val) => {
    if (typeof val != 'undefined') {
      setAccountName(val)
    }
  }

  const handlePixelId = (val) => {
    if (typeof val != 'undefined') {
      setPixelId(val)
    }
  }

  const handleAccessToken = (val) => {
    if (typeof val != 'undefined') {
      setAccessToken(val)
    }
  }

  const handleTestToken = (val) => {
    if (typeof val != 'undefined') {
      setTestToken(val)
    }
  }

  // const onReset = () => {
  //   form.resetFields();
  // };

  const onFinish = (values) => {
    console.log(form.getFieldsValue())
    console.log('Success:', values)
    form.validateFields()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const submitForm = {
      account_name: accountName,
      pixel_id: pixelId,
      access_token: accessToken,
      test_token: testToken,
    }
    await NewProjectStore.saveTiktokPixels(submitForm)
    await NewProjectStore.fetchTiktokPixel('tiktok')
    // getTiktokPixel()
    message.success('Tiktok Pixel was created successfully')
    onCreationSuccess()
    // onReset()
  }

  return (
    <div className="filter-section">
      {/* {showCreateTiktokPixel && ( */}
      <Form
        name="form"
        form={form}
        onSubmit={handleSubmit}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={[16, 10]}>
          <Col>
            <Row>
              <Title level={3}>Create a new Tiktok pixel</Title>
            </Row>
            <Row style={{ marginBottom: 10 }}></Row>

            <Form.Item
              name="accountName"
              rules={[
                {
                  required: true,
                  message: 'Please write Account name',
                },
              ]}
            >
              {!isDetail && (
                <div style={{ width: 290 }}>
                  <Input
                    type="text"
                    placeholder="Account name"
                    onChange={(e) => handleAccountName(e.target.value)}
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              name="pixelId"
              rules={[
                {
                  required: true,
                  message: 'Please write Pixel id',
                },
              ]}
            >
              {!isDetail && (
                <div style={{ width: 290 }}>
                  <Input
                    type="text"
                    placeholder="Pixel id"
                    onChange={(e) => handlePixelId(e.target.value)}
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              name="accessToken"
              rules={[
                {
                  required: true,
                  message: 'Please write Access token',
                },
              ]}
            >
              {!isDetail && (
                <div style={{ width: 290 }}>
                  <Input
                    type="text"
                    placeholder="Access token"
                    onChange={(e) => handleAccessToken(e.target.value)}
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              name="testToken"
              rules={[
                {
                  required: true,
                  message: 'Please write Test token',
                },
              ]}
            >
              {!isDetail && (
                <div style={{ width: 290 }}>
                  <Input
                    type="text"
                    placeholder="Test token"
                    onChange={(e) => handleTestToken(e.target.value)}
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="submit"
                icon={<PlusOutlined />}
                onClick={handleSubmit}
              >
                Create Tiktok pixel
              </Button>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
            </Form.Item>
            <Row style={{ marginBottom: 10 }}></Row>
          </Col>
        </Row>
      </Form>
      {/* )} */}
    </div>
  )
}
export default observer(TiktokPixel)
