import { Card, Col, Row, Space, Spin, Typography } from 'antd'
import qs from 'qs'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import CampaignTable from './CampaignTable'
import { CampaignsStore } from '../../stores'
import Filter from './Filter'
import './campaigns.scss'
import { useGlobalData } from '../../components'
import TiktokTable from './TiktokTable'

const { Title } = Typography

function Campaigns(props) {
  const { networks } = useGlobalData()
  const { state, setFilter, filter, campaignStats } = CampaignsStore
  const isLoading = state === 'pending'

  useEffect(() => {
    let _filter = qs.parse(window.location.hash.split('?')[1])

    if (_filter.account_id) {
      _filter.account_id = _filter.account_id.split(',')
    }
    _filter = Object.keys(_filter).length > 0 ? _filter : filter
    if (networks && networks.length > 0 && !filter.network) {
      setFilter({
        ..._filter,
        network: networks[0],
      })
    } else {
      setFilter(_filter)
    }
    // eslint-disable-next-line
  }, [networks])

  return (
    <div className="campaign-page">
      <Filter {...props} filter={filter} onChangeFilter={setFilter} />

      <Row gutter={[16, 64]}>
        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="danger">
                Total Cost
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>{currency(campaignStats.cost).format()}</Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="success">
                Total Revenue
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(campaignStats.revenue).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="warning">
                Total Net
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title
                  level={5}
                  type={campaignStats.net >= 0 ? 'success' : 'danger'}
                >
                  {currency(campaignStats.net).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} style={{ color: blue[4] }}>
                Total ROI
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title
                  level={5}
                  type={campaignStats.roi >= 0 ? 'success' : 'danger'}
                >
                  {Math.round(campaignStats.roi * 100) / 100}%
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          {filter.network === 'tiktok' ? (
            <TiktokTable {...props} />
          ) : (
            <CampaignTable {...props} />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default observer(Campaigns)
