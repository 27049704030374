import { fetch } from '../utils'

export default {
  async getPlatformAccounts(platformName) {
    return fetch(`/campaign_mang/${platformName}/accounts`)
  },

  async getTonicCountries() {
    return fetch(`/campaign_mang/tonic/countries`)
  },

  async getOfferByAccountAndCountries({ account_id, country_code }) {
    return fetch(`/campaign_mang/tonic/${account_id}/${country_code}/offers`)
  },

  async getTiktokPixel() {
    return fetch(`/pixel_mang/tiktok`)
  },

  async saveTiktokPixel(body) {
    const url = `/pixel_mang/tiktok`
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },

  async updateTiktokPixel(body) {
    const url = `/pixel_mang/tiktok`
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
  },

  async saveCampaign(body) {
    const url = `/campaign_mang/tonic/campaigns`
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },
}
