import {
  Button,
  Image,
  Col,
  Tag,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import moment from 'moment'
import { CopyOutlined, ReadOutlined } from '@ant-design/icons'
import {
  localStorage,
  sortByDate,
  sortByNumber,
  sortByBoolean,
} from '../../../utils'
import parseFilterValue from '../../../utils/parseDateValue'
import { CampaignsStore, AdminStore } from '../../../stores'
import columns from '../CampaignTable/columns'
import ColumnCustomizeModal from '../ColumnCustomizeModal'
import Metadata from './Metadata'
import { TRAFFIC_SOURCE_TYPES } from '../../../assets/TrafficSourceTypes'

const { Column } = Table
const DATA_PER_NETWORK_PER_DAY = 'spendbynetworkdaily'
const FILTER_OPTIONS = [
  { label: 'Last 7 days', value: 'week' },
  { label: 'Last 30 days', value: 'month' },
]

function CopyButton({ name }) {
  const [copied, setCopied] = useState(false)

  const handleCopyCampaignName = () => {
    navigator.clipboard.writeText(name)
    setCopied(true)
  }

  return (
    <Tooltip placement="top" title={copied ? 'Copied' : 'Copy to clipboard'}>
      <Button
        icon={<CopyOutlined />}
        size="small"
        onClick={handleCopyCampaignName}
        onBlur={() => setCopied(false)}
      />
    </Tooltip>
  )
}

function DrilldownDataModal(props) {
  const { visible, onCancel, data, parent } = props
  const { filter, fetchDrillDownData, fetchIsFinal } = CampaignsStore
  const { fetchDrillDownDataPerNetwork } = AdminStore
  const [loading, setLoading] = useState(true)
  const [isAdmin] = useState(parent === 'admin')
  const [dataTable, setDataTable] = useState([])
  const [date, setDate] = useState('week')
  const [selectedColumn, setSelectedColumn] = useState(
    localStorage.getObject('drilldownModal.customField') || []
  )
  const [showColumnModal, setShowColumnModal] = useState(false)
  const networkForAdmin = TRAFFIC_SOURCE_TYPES?.filter(
    (item) => data?.network === item.value
  )
  const TONIC_ACCOUNT_ID = 'tarzomedia@gmail.com'

  useEffect(() => {
    setShowColumnModal(false)

    if (visible) {
      isAdmin ? getDrillDownByNetwork(date) : fetchData(date)
    } else {
      setDataTable([])
    }
    // eslint-disable-next-line
  }, [visible])

  async function getIsFinalized(date) {
    if (date) {
      try {
        const data = await fetchIsFinal({
          account_id: TONIC_ACCOUNT_ID,
          date: date,
        })
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function fetchData(dateFilter) {
    const { from, to } = parseFilterValue({ date: dateFilter })
    const campaignID = data?.campaignid

    setLoading(true)
    const resp = await fetchDrillDownData({
      network: filter.network,
      from,
      to,
      campaignID,
    })

    const _dataTable = await Promise.all(
      resp?.data.map(async (item, index) => ({
        ...item,
        key: index,
        is_finalized:
          index === 0 || index === 1 ? await getIsFinalized(item?.date) : true,
      }))
    )

    setDataTable(_dataTable)
    setLoading(false)
  }

  async function getDrillDownByNetwork(dateFilter) {
    const { from, to } = parseFilterValue({ date: dateFilter })
    const network = data?.network

    setLoading(true)

    const resp = await fetchDrillDownDataPerNetwork({
      from,
      to,
      reportName: DATA_PER_NETWORK_PER_DAY,
    })

    const _dataTable = await Promise.all(
      resp?.data.map(async (item, index) => ({
        ...item,
        key: index,
        is_finalized:
          index === 0 || index === 1 ? await getIsFinalized(item?.date) : true,
      }))
    )

    setDataTable(_dataTable)
    setLoading(false)
    setDataTable(_dataTable?.filter((item) => item.network.includes(network)))
  }

  const handleChangeDateType = ({ target: { value } }) => {
    setDate(value)
    isAdmin ? getDrillDownByNetwork(value) : fetchData(value)
  }

  const handleChangeColumn = ({ selected }) => {
    setShowColumnModal(false)
    setSelectedColumn(selected)
    localStorage.setObject('drilldownModal.customField', selected)
  }

  if (!visible) return null

  return (
    <Modal
      title={
        <Space>
          {isAdmin && networkForAdmin && (
            <Image
              src={networkForAdmin.map((network) => network.image)}
              preview={false}
              height={20}
              width={20}
            />
          )}
          {isAdmin ? data?.network : data?.name}
          <CopyButton name={isAdmin ? data?.network : data?.name} />
        </Space>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width="80%"
    >
      <Row gutter={[16, 16]}>
        <Metadata loading={loading} dataTable={dataTable} isAdmin={isAdmin} />

        <Col span={12}>
          <Radio.Group
            options={FILTER_OPTIONS}
            onChange={handleChangeDateType}
            value={date || 'week'}
            optionType="button"
            style={{ marginBottom: 15 }}
          />
        </Col>
        <Col span={12} align="right">
          {!isAdmin && (
            <Button
              icon={<ReadOutlined />}
              onClick={() => setShowColumnModal(true)}
            />
          )}
        </Col>
      </Row>
      {console.log(dataTable)}
      <Table
        size="small"
        dataSource={dataTable}
        loading={loading}
        scroll={{
          x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
          y: 500,
        }}
        pagination={{
          defaultPageSize: 50,
        }}
      >
        <Column
          width={120}
          title="Date"
          dataIndex="date"
          sorter={(a, b) => sortByDate(a, b, 'date')}
          fixed="left"
          render={(item) => moment(item).format('YYYY-MM-DD')}
        />
        <Column
          width={120}
          title="Status"
          dataIndex="is_finalized"
          sorter={(a, b) => sortByBoolean(a, b, 'is_finalized')}
          fixed="left"
          render={(item) => {
            return item ? (
              <Tag color="success">Finalized</Tag>
            ) : (
              <Tag color="red">Not Finalized</Tag>
            )
          }}
        />
        <Column
          width={120}
          title="Cost"
          dataIndex={isAdmin ? 'spend' : 'cost'}
          render={(item) => (
            <span style={{ color: blue[4] }}>{currency(item).format()}</span>
          )}
          sorter={(a, b) => sortByNumber(a, b, isAdmin ? 'spend' : 'cost')}
        />
        {isAdmin && (
          <Column
            width={120}
            title="Revenue"
            dataIndex={'revenue'}
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'revenue')}
          />
        )}
        {isAdmin && (
          <Column
            width={120}
            title="NET"
            dataIndex={'net_profit'}
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'net_profit')}
          />
        )}
        {isAdmin && (
          <Column
            width={120}
            title="ROI"
            dataIndex={'roi'}
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'roi')}
          />
        )}
        {!isAdmin &&
          selectedColumn.map((item) => {
            const columnProps =
              columns.find((i) => i.dataIndex === item.dataIndex) || {}

            return <Column {...columnProps} key={item.dataIndex} />
          })}
      </Table>

      <ColumnCustomizeModal
        visible={showColumnModal}
        onCancel={() => setShowColumnModal(false)}
        onSubmit={handleChangeColumn}
        items={{
          visibility: columns.filter((item) => {
            return !selectedColumn.find((i) => i.dataIndex === item.dataIndex)
          }),
          selected: selectedColumn,
        }}
      />
    </Modal>
  )
}

export default observer(DrilldownDataModal)
