import { Table as AntTable } from 'antd'
import { useEffect, useRef } from 'react'
import classes from 'classnames'
import './table.scss'

export default function Table(props) {
  const { children, resizeColumn = false, ...restProps } = props
  const ref = useRef(null)
  const events = useRef({
    start: null,
    index: null,
    width: null,
  })

  useEffect(() => {
    if (ref.current && resizeColumn) {
      addResizeController()
      initEventsListener()

      return () => {
        window.removeEventListener('mouseup', handleMouseUp)
        window.removeEventListener('mousemove', handleMouseMove)
      }
    }
    // eslint-disable-next-line
  }, [])

  function addResizeController() {
    const colHead = ref.current.querySelectorAll(
      '.ant-table-header thead tr th'
    )
    colHead.forEach((col, index) => {
      const span = makeResizeControl(index)
      const hasResizeControl = col.querySelector('.resize-control')

      if (!hasResizeControl) {
        col.appendChild(span)
      }
    })
  }

  function makeResizeControl(index) {
    const span = document.createElement('span')
    span.className = 'resize-control'
    span.setAttribute('data-index', index)

    span.addEventListener('mousedown', handleMouseDown)
    span.addEventListener('click', (e) => e.stopPropagation())

    return span
  }

  function initEventsListener() {
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }

  function handleMouseDown(e) {
    const col = ref.current.querySelectorAll('.ant-table-body colgroup col')
    const currentCol = col[e.target.dataset.index]
    const width = currentCol.style.width.replace('px', '')

    events.current.start = e.clientX
    events.current.index = e.target.dataset.index
    events.current.width = +width
  }

  function handleMouseUp() {
    events.current = {
      start: null,
      index: null,
    }
  }

  function handleMouseMove(e) {
    const col = ref.current.querySelectorAll('.ant-table-body colgroup col')

    if (events.current.index) {
      const currentCol = col[events.current.index]
      const width = events.current.width
      const distance = events.current.start - e.clientX
      const newWidth = +width - distance

      currentCol.style.width = `${newWidth}px`
    }
  }

  return (
    <AntTable
      {...restProps}
      ref={ref}
      className={classes({
        'ant-table-resize': resizeColumn,
      })}
    >
      {children}
    </AntTable>
  )
}
