import { Col, Row, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useCallback } from 'react'
import currency from 'currency.js'
import { AdminStore } from '../../../stores'
import { localStorage, sortByNumber, sortByString } from '../../../utils'
import { Table, EditableRow, EditableCell } from '../../../components'
import Filter from '../../Campaigns/Filter'
import './MediaBuyerPreformPanel.scss'

const { Column } = Table
const { Title } = Typography
const DATA_PER_MEDIA_BUYER = 'statPerMediaBuyer'

function MediaBuyerPreformPanel(props) {
  // const { networks } = useGlobalData()
  const { rangeDateChanged, setRangeDateChanged } = props
  const [listItems, setListItems] = useState([])
  const [customNetwork, setCustomNetwork] = useState('All')
  const [isMediaBuyer] = useState(true)

  const {
    customFilterMediaBuyer,
    setFilterMediaBuyer = '',
    dataPerMediaBuyerState,
    fetchDataPerMediaBuyer,
  } = AdminStore

  useEffect(() => {
    const customNetwork = localStorage?.getObject('admin.filter')?.network
    setCustomNetwork(customNetwork)
    // eslint-disable-next-line
  }, [customNetwork])

  const getDataPerMediaBuyer = useCallback(async () => {
    const filter = localStorage.getObject('admin.filter')
    const data = await fetchDataPerMediaBuyer({
      from: filter.from,
      to: filter.to,
      reportName: DATA_PER_MEDIA_BUYER,
      network: customNetwork,
    })

    const dataPerMediaBuyerList = data?.map((item) => ({
      ...item,
    }))

    setListItems(dataPerMediaBuyerList)
    setRangeDateChanged(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilterMediaBuyer.date, customNetwork, rangeDateChanged])

  useEffect(() => {
    getDataPerMediaBuyer()
    // eslint-disable-next-line
  }, [getDataPerMediaBuyer])

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  return (
    <>
      <div className="mediaBuyer-upper-banner">
        <Title level={5} className="mediaBuyer-title">
          Performance per Media buyer
        </Title>
        <Filter
          className="mediaBuyer-filter-wrapper"
          {...props}
          filter={customFilterMediaBuyer}
          isMediaBuyer={isMediaBuyer}
          onChangeFilter={setFilterMediaBuyer}
          setCustomNetwork={setCustomNetwork}
          setRangeDateChanged={setRangeDateChanged}
        />
      </div>

      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Table
            size="small"
            dataSource={listItems}
            components={components}
            loading={dataPerMediaBuyerState === 'pending'}
            scroll={{
              x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
              y: '30vh',
            }}
            pagination={{
              defaultPageSize: 20,
            }}
            resizeColumn
          >
            <Column
              ellipsis
              width={100}
              title="Media Buyer"
              dataIndex="buyer"
              sorter={(a, b) => sortByString(a, b, 'buyer')}
              fixed="left"
            />
            <Column
              ellipsis
              width={100}
              title="Network"
              dataIndex="network"
              sorter={(a, b) => sortByString(a, b, 'network')}
            />
            <Column
              width={120}
              title="Cost"
              dataIndex="spend"
              sorter={(a, b) => sortByNumber(a, b, 'spend')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />
            <Column
              width={120}
              title="Revenue"
              dataIndex="revenue"
              sorter={(a, b) => sortByNumber(a, b, 'revenue')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />
            <Column
              width={120}
              title="NET"
              dataIndex="net_profit"
              sorter={(a, b) => sortByNumber(a, b, 'net_profit')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />
            <Column
              width={120}
              title="ROI"
              dataIndex="roi"
              sorter={(a, b) => sortByNumber(a, b, 'roi')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default observer(MediaBuyerPreformPanel)
