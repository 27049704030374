import { makeAutoObservable } from 'mobx'
import { AccountsStore } from '..'
import qs from 'qs'
import api from '../../api'
import { localStorage, normalizationUrl } from '../../utils'

const DEFAULT_FILTER = {
  date: 'today',
}

class AdminStore {
  campaignStats = {}
  campaigns = []
  totalDataPerNetworkList = []
  filter = this.getDefaultFilter()
  customFilterMediaBuyer = this.getCustomFilter()
  state = 'pending' // 'pending', 'done' or 'error'
  resourceTableState = 'pending'
  dataPerMediaBuyerState = 'pending'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  getDefaultFilter() {
    const filter = localStorage.getObject('campaign.filter')
    return filter && Object.keys(filter).length > 0 ? filter : DEFAULT_FILTER
  }

  getCustomFilter() {
    const filter = localStorage.getObject('admin.filter')
    return filter && Object.keys(filter).length > 0 ? filter : DEFAULT_FILTER
  }

  updateUrl() {
    const params = qs.stringify(this.filter, { arrayFormat: 'comma' })
    const { pathname } = normalizationUrl()
    const url = `/#/${pathname}${params ? `?${params}` : ''}`
    window.history.pushState({}, '', url)
  }

  setFilter(filter) {
    localStorage.setObject('campaign.filter', filter)
    this.filter = filter
    this.updateUrl()
    AccountsStore.fetchAccounts(this.filter.network)
  }

  setFilterMediaBuyer(customFilterMediaBuyer) {
    localStorage.setObject('admin.filter', customFilterMediaBuyer)
    this.customFilterMediaBuyer = customFilterMediaBuyer
    this.updateUrl()
    AccountsStore.fetchAccounts(this.customFilterMediaBuyer.network)
  }
  // *fetchTotal() {
  //   this.state = 'pending'

  //   let params = parseDateValue(this.filter)

  //   try {
  //     let {
  //       data: { data },
  //     } = yield api.campaigns.getTotal(params)

  //     if (data.length > 0) {
  //       this.campaignStats = data.reduce((totalResult, item) => {
  //         totalResult.cost = (totalResult.cost || 0) + item.cost
  //         totalResult.revenue = (totalResult.revenue || 0) + item.revenue
  //         totalResult.net = totalResult.revenue
  //           ? totalResult.revenue - totalResult.cost
  //           : 0
  //         totalResult.roi = totalResult.revenue
  //           ? ((totalResult.revenue - totalResult.cost) / totalResult.cost) *
  //             100
  //           : 0

  //         return totalResult
  //       }, {})
  //     }

  //     this.state = 'done'
  //   } catch (e) {
  //     this.state = 'error'
  //     console.log('Some thing went wrong')
  //   }
  // }

  // *fetchCampaigns() {
  //   //this.resourceTableState = 'pending'

  //   let params = parseDateValue(this.filter)

  //   try {
  //     let {
  //       data: { data },
  //     } = yield api.campaigns.getCampaignStats(params)

  //     this.totalDataPerNetworkList = data.map((item) => ({
  //       ...item,
  //       key: item.campaignid,
  //     }))
  //     //this.resourceTableState = 'done'
  //   } catch (e) {
  //     this.resourceTableState = 'error'
  //     console.log('Some thing went wrong')
  //   }
  // }

  *fetchIsAdmin() {
    try {
      let { data } = yield api.admin.getisAdmin()
      return data
    } catch (e) {
      console.log('Some thing went wrong ' + e)
    }
  }

  *fetchTotalData(options) {
    this.state = 'pending'
    try {
      let { data } = yield api.admin.getTotalData({
        ...options,
      })
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong ' + e)
    }
  }

  *fetchTotalDataPerNetwork(options) {
    this.resourceTableState = 'pending'

    try {
      let {
        data: { data },
      } = yield api.admin.getTotalDataPerNetwork(options)
      this.resourceTableState = 'done'
      return data
    } catch (e) {
      this.resourceTableState = 'error'
      console.log('Some thing went wrong')
    }
  }

  *fetchDrillDownDataPerNetwork(options) {
    try {
      const { data } = yield api.admin.getTotalDataPerNetwork(options)
      return data
    } catch (e) {
      console.log('Some thing went wrong')
    }
  }

  *fetchDataPerMediaBuyer(options) {
    this.dataPerMediaBuyerState = 'pending'
    try {
      let {
        data: { data },
      } = yield api.admin.getDataPerMediaBuyer(options)
      this.dataPerMediaBuyerState = 'done'
      return data
    } catch (e) {
      this.dataPerMediaBuyerState = 'error'
      console.log('Some thing went wrong')
    }
  }
}

export default new AdminStore()
