import { Col, Row } from 'antd'
import qs from 'qs'
import './reports.scss'
import { AccountsStore, ReportsStore } from '../../stores'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ReportTable from './ReportTable'
import Filter from './Filter'

function Reports(props) {
  const { setFilter, filter } = ReportsStore
  const { accounts } = AccountsStore

  useEffect(() => {
    setFilter(qs.parse(props.location.search.slice(1)))
    // eslint-disable-next-line
  }, [])

  return (
    <div className="report-page">
      <Filter
        {...props}
        accounts={accounts}
        filter={filter}
        onChangeFilter={setFilter}
      />

      <Row gutter={[16, 64]}>
        <Col span={24}>
          <ReportTable {...props} />
        </Col>
      </Row>
    </div>
  )
}

export default observer(Reports)
