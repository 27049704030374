import { Col, Row } from 'antd'
// import qs from 'qs'
import './campaignsManagement.scss'
// import { CampaignsManagementStore } from '../../stores'
// import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import CampaignsManagementTable from './CampaignsManagementTable'
// import Filter from './Filter'
// import { useGlobalData } from '../../components'

function CampaignsManagement(props) {
  // const { networks } = useGlobalData()
  // const { setFilter, filter } = CampaignsManagementStore

  // useEffect(() => {
  //   let _filter = qs.parse(window.location.hash.split('?')[1])

  //   if (_filter.account_id) {
  //     _filter.account_id = _filter.account_id.split(',')
  //   }
  //   _filter = Object.keys(_filter).length > 0 ? _filter : filter
  //   if (networks && networks.length > 0 && !filter.network) {
  //     setFilter({
  //       ..._filter,
  //       network: networks[0],
  //     })
  //   } else {
  //     setFilter(_filter)
  //   }
  //   // eslint-disable-next-line
  // }, [networks])

  return (
    <div className="cmpaignsManagement-page">
      {/* <Filter {...props} filter={filter} onChangeFilter={setFilter} /> */}

      <Row gutter={[16, 64]}>
        <Col span={24}>
          <CampaignsManagementTable {...props} />
        </Col>
      </Row>
    </div>
  )
}

export default observer(CampaignsManagement)
