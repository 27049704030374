import React, { useEffect, useState, useCallback } from 'react'
import {
  Col,
  Row,
  Tag,
  Input,
  Button,
  Space,
  Switch,
  Spin,
  Radio,
  message,
} from 'antd'
import {
  ReadOutlined,
  ExportOutlined,
  AppstoreOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import currency from 'currency.js'
import { CSVLink } from 'react-csv'
import { CampaignsStore } from '../../../stores'
import {
  localStorage,
  sortByNumber,
  sortByString,
  sortByBoolean,
} from '../../../utils'
import columns from './columns'
import ColumnCustomizeModal from '../ColumnCustomizeModal'
import DrilldownDataModal from '../DrilldownDataModal'
import KeywordDrillDownModal from '../KeywordDrillDownModal'
import { Table, EditableRow, EditableCell } from '../../../components'

const { Column } = Table
const { Search } = Input
const finishRows = {}
const FACEBOOK_STAGE_NAMESPACE = 'facebook.stage'

function CampaignTable() {
  const [stageData, setStageData] = useState(
    localStorage.getObject(FACEBOOK_STAGE_NAMESPACE) || {
      currentTab: 'campaign',
      selectedRowKeys: [],
      selectedAds: null,
    }
  )
  const [selectedColumn, setSelectedColumn] = useState(
    localStorage.getObject('facebook.customField') || []
  )
  const [listItems, setListItems] = useState([])
  const [drillDownModal, setDrillDownModal] = useState(null)
  const [reportModal, setReportModal] = useState(null)
  const [showColumnModal, setShowColumnModal] = useState(false)
  const [query, setQuery] = useState('')
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loadingRows, setLoadingRows] = useState({})
  const [isFinalized, setIsFinalized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const {
    campaigns,
    adgroupList,
    adsList,
    resourceTableState,
    updateCampaignById,
    fetchAdgroupList,
    fetchCampaigns,
    fetchIsFinal,
    fetchAdsList,
    toggleCampaign,
    filter,
  } = CampaignsStore
  // const { search } = normalizationUrl()
  const isTaboola = filter.network === 'taboola'
  const isFacebook = filter.network === 'facebook'
  const TONIC_ACCOUNT_ID = 'tarzomedia@gmail.com'
  const isCampaign = stageData.currentTab === 'campaign'
  const isAdGroups = stageData.currentTab === 'adgroups'
  const isAds = stageData.currentTab === 'ads'

  useEffect(() => {
    return () => {
      localStorage.removeItem(FACEBOOK_STAGE_NAMESPACE)
    }
    // eslint-disable-next-line
  }, [])

  const getisFinalized = useCallback(async () => {
    const date = localStorage.getObject('campaign.filter')
    if (date) {
      try {
        setIsLoading(true)
        const data = await fetchIsFinal({
          account_id: TONIC_ACCOUNT_ID,
          date: date.from,
        })
        setIsLoading(false)
        setIsFinalized(data)
      } catch (error) {
        console.log(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])
  //campaigns

  useEffect(() => {
    getisFinalized()
    // eslint-disable-next-line
  }, [getisFinalized])

  useEffect(() => {
    if (isAdGroups) {
      fetchAdgroupList({
        campaignID: stageData.selectedRowKeys.join(','),
      })
    }
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    if (isAdGroups) {
      getAdgroupList()
    }
    if (isAds) {
      getAdsList()
    }
    // eslint-disable-next-line
  }, [stageData.currentTab])

  useEffect(() => {
    if (isCampaign) {
      setListItems(getListWithQuery(campaigns))
    } else if (isAdGroups) {
      setListItems(getListWithQuery(adgroupList))
    } else if (isAds) {
      setListItems(getListWithQuery(adsList))
    }
    // eslint-disable-next-line
  }, [campaigns, adgroupList, adsList, isCampaign])

  useEffect(() => {
    if (isCampaign) {
      setListItems(getListWithQuery(campaigns))
    }
    // eslint-disable-next-line
  }, [query, resourceTableState, isCampaign])

  function getListWithQuery(list) {
    if (query) {
      return list.filter((item) => item.name.includes(query))
    }
    return list
  }

  function getAdgroupList() {
    fetchAdgroupList({
      campaignID: stageData.selectedRowKeys.join(','),
    })
  }

  function getAdsList() {
    fetchAdsList({
      campaignID: stageData.selectedAds,
    })
  }

  const onSearch = ({ target: { value } }) => setQuery(value)

  const handleChangeColumn = ({ selected }) => {
    setShowColumnModal(false)
    setSelectedColumn(selected)
    localStorage.setObject('facebook.customField', selected)
  }

  const handleClickEnable = async (data) => {
    let dataLevel = 'campaign'
    let dataItemId = data.campaignid

    if (isAdGroups) {
      dataLevel = 'adset'
      dataItemId = data.adgroup_id
    }

    setLoadingRows({
      ...loadingRows,
      [dataItemId]: true,
    })

    finishRows[dataItemId] = false

    try {
      await toggleCampaign({
        body: {
          network: data.network,
          level: dataLevel,
          item_id: dataItemId,
          field: 'enable',
          old_value: !!data.enabled,
          new_value: data.checked,
          name: data.name,
        },
        isAdGroups,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingRows({
        ...loadingRows,
        [dataItemId]: false,
      })
      finishRows[dataItemId] = true
    }
  }

  const checkData = (bodyData) => {
    console.log({ bodyData })
    if (bodyData.new_value < 1) {
      message.error(`Minimum budget is 20$`)
      return false
    }
    if (
      bodyData.new_value / bodyData.old_value >= 3 ||
      bodyData.old_value / bodyData.new_value >= 3
    ) {
      if (
        confirm(
          `You are about to make a drastic change to the budget value \n
          Old budget: ${bodyData.old_value}  New budget: ${bodyData.new_value} \n
          Are you sure?`
        )
      ) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  const createOnCellObject = (record, columnProps) => {
    return {
      record,
      editable: true,
      dataIndex: columnProps.dataIndex,
      title: columnProps.title,

      handleSave: async (data) => {
        let dataLevel = 'campaign'
        let dataItemId = data.campaignid
        if (isAdGroups) {
          dataLevel = 'adset'
          dataItemId = data.adgroup_id
        }
        const bodyData = {
          name: data.name,
          field: columnProps.dataIndex,
          item_id: dataItemId,
          level: dataLevel,
          old_value: +record[columnProps.dataIndex],
          new_value: +data[columnProps.dataIndex],
        }
        if (checkData(bodyData)) {
          await updateCampaignById({
            body: bodyData,
          })
          if (isAdGroups) {
            await fetchAdgroupList({
              campaignID: stageData.selectedRowKeys.join(','),
            })
          } else {
            await fetchCampaigns()
          }
        }
      },
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    const stage = { ...stageData }
    stage.selectedRowKeys = newSelectedRowKeys
    setStageData(stage)
    // setSelectedRowKeys(newSelectedRowKeys)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const rowSelection = {
    selectedRowKeys: stageData.selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.enabled === 0,
    }),
  }
  const handleSelectTab = (tab) => {
    const stage = { ...stageData }
    stage.currentTab = tab
    localStorage.setObject(FACEBOOK_STAGE_NAMESPACE, stage)
    setStageData(stage)
  }
  const onClickCampaignName = (row) => {
    if (isCampaign) {
      const stage = { ...stageData }
      stage.currentTab = 'adgroups'
      stage.selectedRowKeys = [row.campaignid]

      localStorage.setObject(FACEBOOK_STAGE_NAMESPACE, stage)
      setStageData(stage)
    }

    if (isAdGroups) {
      const stage = { ...stageData }
      stage.currentTab = 'ads'
      stage.selectedAds = row.campaignid
      localStorage.setObject(FACEBOOK_STAGE_NAMESPACE, stage)
      setStageData(stage)
    }
  }

  const Footer = () => {
    const totalCost = listItems.reduce((result, item) => {
      result += +item.cost
      return result
    }, 0)
    const totalRevenue = listItems.reduce((result, item) => {
      result += +item.revenue
      return result
    }, 0)
    const totalNETProfit = listItems.reduce((result, item) => {
      result += +item.net_profit
      return result
    }, 0)
    const totalROI = listItems.reduce(
      (result, item) => {
        result.revenue += +item.revenue
        result.cost += +item.cost
        return result
      },
      { revenue: 0, cost: 0 }
    )
    return (
      <Row style={{ padding: '4px 0' }}>
        <Col flex={1}>
          Name: <strong>{listItems.length}</strong> items
        </Col>
        <Col flex={1}>
          Cost: <strong>{currency(totalCost).format()}</strong>
        </Col>
        <Col flex={1}>
          Revenue: <strong>{currency(totalRevenue).format()}</strong>
        </Col>
        <Col flex={1}>
          NET Profit: <strong>{currency(totalNETProfit).format()}</strong>
        </Col>
        <Col flex={1}>
          ROI:{' '}
          <strong>
            {Math.round((totalROI.revenue / totalROI.cost) * 100)}%
          </strong>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={12}>
          <Radio.Group
            value={stageData.currentTab}
            onChange={(e) => handleSelectTab(e.target.value)}
          >
            <Radio.Button value="campaign">Campaign</Radio.Button>
            {isFacebook && (
              <Radio.Button value="adgroups">
                ({stageData?.selectedRowKeys?.length || 0}) Ad Set
              </Radio.Button>
            )}
            {/* <Radio.Button value="ads">Ads</Radio.Button> */}
          </Radio.Group>
        </Col>
        <Col span={12} align="right">
          <Space size="small">
            {isLoading ? (
              <Spin style={{ padding: '0 2.4em' }} />
            ) : isFinalized ? (
              <Tag
                color="success"
                style={{ fontSize: 14, padding: '5px 15px' }}
              >
                Finalized
              </Tag>
            ) : (
              <Tag color="red" style={{ fontSize: 14, padding: '5px 15px' }}>
                Not Finalized
              </Tag>
            )}
            <CSVLink
              data={listItems}
              target="_blank"
              filename="campaigns-export.csv"
            >
              <Button icon={<ExportOutlined />}>Export</Button>
            </CSVLink>
            <Search
              allowClear
              placeholder="Search by name"
              value={query}
              onChange={onSearch}
              style={{ width: 250 }}
            />
            <Button
              icon={<ReadOutlined />}
              onClick={() => setShowColumnModal(true)}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Table
            size="small"
            dataSource={listItems}
            components={components}
            rowSelection={isCampaign ? rowSelection : undefined}
            loading={isAdGroups ? 'done' : resourceTableState === 'pending'}
            scroll={{
              x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
              y: '55vh',
            }}
            pagination={{
              defaultPageSize: 50,
            }}
            footer={Footer}
            resizeColumn
          >
            {(isTaboola || isFacebook) && (
              <Column
                width={20}
                dataIndex="enabled"
                fixed="left"
                render={(item, row) => (
                  <Space>
                    <Switch
                      size="small"
                      checked={item}
                      onChange={(checked) =>
                        handleClickEnable({ checked, ...row })
                      }
                      loading={
                        isAdGroups
                          ? loadingRows[row.adgroup_id] &&
                            !finishRows[row.adgroup_id]
                          : loadingRows[row.campaignid] &&
                            !finishRows[row.campaignid]
                      }
                    />
                  </Space>
                )}
              />
            )}
            <Column
              ellipsis
              width={100}
              title="Name"
              dataIndex={isAdGroups ? 'adgroup_name' : 'name'}
              sorter={(a, b) => sortByString(a, b, 'name')}
              render={(item, row) => {
                return (
                  <Space className="row-actions">
                    <Space className="row-actions-text">
                      <span
                        className="text"
                        onClick={() => onClickCampaignName(row)}
                      >
                        {item}
                      </span>
                      {/* </LinkComponent> */}
                      <Button
                        icon={<FileSearchOutlined />}
                        size="small"
                        onClick={() => setReportModal(row)}
                      />
                    </Space>
                    <Button
                      icon={<AppstoreOutlined />}
                      size="small"
                      onClick={() => setDrillDownModal(row)}
                    />
                  </Space>
                )
              }}
              fixed="left"
            />
            <Column
              ellipsis
              width={100}
              title={isAdGroups ? 'Ad Set ID' : 'Campaign ID'}
              dataIndex={isAdGroups ? 'adgroup_id' : 'campaignid'}
              sorter={(a, b) => sortByString(a, b, 'campaignid')}
            />
            <Column
              width={100}
              title="Status"
              dataIndex="enabled"
              sorter={(a, b) => sortByBoolean(a, b, 'enabled')}
              render={(item) => {
                const typeNumber = typeof item === 'number' && item === 1
                const typeString = typeof item === 'string' && item === 'enable'
                return typeNumber || typeString ? (
                  <Tag color="success">Enabled</Tag>
                ) : (
                  <Tag color="red">Disabled</Tag>
                )
              }}
            />
            <Column
              width={120}
              title="Cost"
              dataIndex="cost"
              sorter={(a, b) => sortByNumber(a, b, 'cost')}
              defaultSortOrder="descend"
              render={(item) => currency(item).format()}
            />

            {selectedColumn &&
              selectedColumn.map((item) => {
                const columnProps =
                  columns.find((i) => i.dataIndex === item.dataIndex) || {}
                if (isAdGroups && item.dataIndex === 'created_time') return

                if (columnProps.editable) {
                  columnProps.onCell = (record) => {
                    return createOnCellObject(record, columnProps)
                  }
                }
                return (
                  <Column
                    position="topCenter"
                    {...columnProps}
                    key={item.dataIndex}
                  />
                )
              })}
          </Table>
        </Col>
      </Row>

      <ColumnCustomizeModal
        visible={showColumnModal}
        onCancel={() => setShowColumnModal(false)}
        onSubmit={handleChangeColumn}
        items={{
          visibility: columns.filter((item) => {
            return !selectedColumn.find((i) => i.dataIndex === item.dataIndex)
          }),
          selected: selectedColumn,
        }}
      />

      <DrilldownDataModal
        data={drillDownModal}
        visible={!!drillDownModal}
        onCancel={() => setDrillDownModal(null)}
      />

      <KeywordDrillDownModal
        data={reportModal}
        visible={!!reportModal}
        onCancel={() => setReportModal(null)}
      />
    </>
  )
}

export default observer(CampaignTable)
