import { makeAutoObservable } from 'mobx'
import api from '../../api'

class NewProjectStore {
  accounts = []
  countries = []
  accountAndCountries = []
  state = 'pending' // 'pending', 'done' or 'error'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  // ============ getTonicAccount (from api/newProject) ============
  *fetchPlatformsAccounts(platformName) {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = yield api.newProject.getPlatformAccounts(platformName)
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong "fetchTonicAccount"')
    }
  }

  // ============ getTonicCountries (from api/newProject) ============
  *fetchCountries() {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = yield api.newProject.getTonicCountries()
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong with "fetchCountries"')
    }
  }

  // ============ getOfferByAccountAndCountries (from api/newProject) ============
  async fetchOfferByAccountAndCountries({ account_id, country_code }) {
    this.state = 'pending'

    try {
      let response = await api.newProject.getOfferByAccountAndCountries({
        account_id,
        country_code,
      })
      let { data } = response.data
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Something went wrong with "fetchOfferByAccountAndCountries"')
      throw e
    }
  }

  // ============ getTiktokPixel (from api/newProject) ============
  async fetchTiktokPixel() {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = await api.newProject.getTiktokPixel()
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong with "fetchTiktokPixel"')
    }
  }

  async saveTiktokPixels(fromData) {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = await api.newProject.saveTiktokPixel(fromData)
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong with "saveTiktokPixel"')
    }
  }

  async updateTiktokPixels(fromData) {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = await api.newProject.updateTiktokPixel(fromData)
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong with "updateTiktokPixels"')
    }
  }

  async saveCampaigns(fromData) {
    this.state = 'pending'

    try {
      let {
        data: { data },
      } = await api.newProject.saveCampaign(fromData)
      this.state = 'done'
      return data
    } catch (e) {
      this.state = 'error'
      return null
    }
  }
}

export default new NewProjectStore()
