import { useAuth0 } from '@auth0/auth0-react'
import {
  Input,
  Select,
  Col,
  Row,
  Button,
  message,
  Form,
  Typography,
  List,
} from 'antd'
import { RocketOutlined, ClearOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { NewProjectStore } from '../../stores'
import '../../components/GlobalDataProvider/index'
import mediaBuyerNames from '../../components/JsonDB/mediaBuyerNames.json'
import TiktokPixel from '../NewProject/TiktokPixel'

function NewFilter(props) {
  const { isDetail } = props

  const { Title } = Typography

  const initialState = {
    selectAccount: [],
    accountOptions: null,
    selectCountry: [],
    countryOptions: null,
    selectVertical: [],
    verticalOptions: null,
    selectNetwork: [],
    showFacebookAccountList: false,
    selectFacebookPixel: null,
    facebookPixelOptions: null,
    showTiktokAccountList: false,
    selectTiktokPixel: null,
    showTiktokPixelWizard: false,
    tiktokPixelOptions: null,
    selectMBN: [],
    newCampaigns: [],
    MBNOptions: null,
    selectCallback: null,
    campaignName: null,
    inputFreeText: '',
  }
  const [
    {
      selectAccount,
      accountOptions,
      selectCountry,
      countryOptions,
      selectVertical,
      verticalOptions,
      selectNetwork,
      showFacebookAccountList,
      selectFacebookPixel,
      facebookPixelOptions,
      showTiktokAccountList,
      selectTiktokPixel,
      showTiktokPixelWizard,
      tiktokPixelOptions,
      selectMBN,
      newCampaigns,
      MBNOptions,
      selectCallback,
      campaignName,
      inputFreeText,
    },
    setState,
  ] = useState(initialState)

  const clearState = () => {
    setState({ ...initialState, MBNOptions: MBNOptions })
    fetchTonicAccounts()
    getFetchCountries()
    //getNetworkDB()
  }

  const [loadings, setLoadings] = useState([])

  const { TextArea } = Input

  const { user = {} } = useAuth0()
  const { state } = NewProjectStore
  const isNewProjectLoading = state === 'pending'

  const [form] = Form.useForm()

  const tonicCallbackDB = [
    {
      value: 'Outbrain',
      key: 'outbrain',
    },
    {
      value: 'Taboola',
      key: 'taboola',
    },
    {
      value: 'Voluum',
      key: 'voluum',
    },
  ]

  const networkDB = [
    {
      value: 'Facebook',
      key: 'fb',
    },
    {
      value: 'Outbrain',
      key: 'ob',
    },
    {
      value: 'Taboola',
      key: 'tb',
    },
    {
      value: 'TikTok',
      key: 'tk',
    },
  ]

  const fetchTonicAccounts = useCallback(async () => {
    const accounts = await NewProjectStore.fetchPlatformsAccounts('tonic')
    const _accountOptions = accounts.map((item) => ({
      key: item.account_id,
      value: item.name,
    }))
    setState((prevState) => ({
      ...prevState,
      ['accountOptions']: _accountOptions,
    }))
  }, [])
  useEffect(() => {
    fetchTonicAccounts()
  }, [fetchTonicAccounts])

  const handleTonicAccount = (val, data) => {
    if (data) {
      setState((prevState) => ({ ...prevState, ['selectAccount']: data }))
      console.log(data)
    }
  }
  const getFetchCountries = useCallback(async () => {
    const countries = await NewProjectStore.fetchCountries()
    const _countryOptions = countries.map((item) => ({
      key: item.code,
      value: item.name,
    }))
    setState((prevState) => ({
      ...prevState,
      ['countryOptions']: _countryOptions,
    }))
  }, [])

  useEffect(() => {
    getFetchCountries()
  }, [getFetchCountries])

  const handleTonicCountry = async (val, data) => {
    if (data.length > 0) {
      setState((prevState) => ({
        ...prevState,
        ['selectCountry']: data.map((item) => item),
      }))
      await getFetchOfferByAccountAndCountries(selectAccount.key, data[0].key)
    }
  }

  const getFetchOfferByAccountAndCountries = async (
    account_id,
    country_code
  ) => {
    try {
      const verticals = await NewProjectStore.fetchOfferByAccountAndCountries({
        account_id,
        country_code,
      })
      if (verticals) {
        const _verticalOptions = verticals.map((item) => ({
          key: item.id,
          value: item.name,
        }))
        setState((prevState) => ({
          ...prevState,
          ['verticalOptions']: _verticalOptions,
        }))
      }
    } catch (e) {
      message.error('Error fetching verticals')
    }
  }
  const handleTonicVertical = (val, data) => {
    if (data) {
      setState((prevState) => ({ ...prevState, ['selectVertical']: data }))
      handleCampaignName()
    }
  }

  const handleNetwork = async (val, data) => {
    console.log('pixel', {
      val,
      data,
    })
    if (data) {
      setState((prevState) => ({ ...prevState, ['selectNetwork']: data }))
      if (data.key === 'fb') {
        setState((prevState) => ({
          ...prevState,
          ['showFacebookAccountList']: true,
        }))
        const facebookPixel = await NewProjectStore.fetchPlatformsAccounts(
          'facebook'
        )
        const _facebookPixelOptions = facebookPixel.map((item) => ({
          key: item.account_name,
          value: item.account_name,
        }))

        setState((prevState) => ({
          ...prevState,
          ['facebookPixelOptions']: _facebookPixelOptions,
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          ['showFacebookAccountList']: false,
        }))
      }

      if (data.key === 'tk') {
        setState((prevState) => ({
          ...prevState,
          ['showTiktokAccountList']: true,
        }))
        const tiktokPixel = await NewProjectStore.fetchTiktokPixel('tiktok')
        const _tiktokPixelOptions = tiktokPixel.map((item) => ({
          key: item.id,
          value: item.account_name,
        }))
        setState((prevState) => ({
          ...prevState,
          ['tiktokPixelOptions']: _tiktokPixelOptions,
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          ['showTiktokAccountList']: false,
        }))
        setState((prevState) => ({
          ...prevState,
          ['showTiktokPixelWizard']: false,
        }))
      }
      handleCampaignName()
    }
  }

  const handleFacebookAccount = (val, data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        ['selectFacebookPixel']: data.key,
      }))
    } else {
      setState((prevState) => ({ ...prevState, ['selectFacebookPixel']: null }))
    }
  }

  const handleTiktokPixel = (val, data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        ['selectTiktokPixel']: data,
      }))
    }
  }

  const handleShowCreateTiktokPixel = async () => {
    setState((prevState) => ({ ...prevState, ['showTiktokPixelWizard']: true }))
  }

  const handleTonicCallback = (val, data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        ['selectCallback']: data.value,
      }))
    }
  }

  const getMBNDB = useCallback(async () => {
    mediaBuyerNames.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    })
    const _mediaBuyerNameOptions = mediaBuyerNames?.map((item) => ({
      value: item.name,
      key: item.mbn_id,
    }))

    setState((prevState) => ({
      ...prevState,
      ['MBNOptions']: _mediaBuyerNameOptions,
    }))
  }, [])

  useEffect(() => {
    getMBNDB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMBNDB])

  const handleTonicMBN = (val, data) => {
    if (data) {
      // console.log({ data })
      setState((prevState) => ({ ...prevState, ['selectMBN']: data }))
      handleCampaignName()
    }
  }

  const handleFreeText = (val) => {
    if (typeof val != 'undefined') {
      setState((prevState) => ({ ...prevState, ['inputFreeText']: val }))
    }
  }

  useEffect(() => {
    const getCampaignName = () => {
      const newCampaiganName =
        `{country_code}-{lang_code}-${selectNetwork.key}-${selectMBN.value}-{vertical}-${inputFreeText}`
          .toLowerCase()
          .replace(/ /g, '')

      setState((prevState) => ({
        ...prevState,
        ['campaignName']: newCampaiganName,
      }))
    }
    getCampaignName()
  }, [campaignName, selectNetwork, selectMBN, inputFreeText])

  const handleCampaignName = (value) => {
    if (value == null) {
      setState((prevState) => ({ ...prevState, ['campaignName']: value }))
    }
  }

  const onFinish = (values) => {
    console.log(form.getFieldsValue())
    console.log('Success:', values)
    form.validateFields()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 3000)
  }

  const checkData = (submitForm) => {
    console.log({ submitForm })
    const {
      account_id,
      countries,
      facebook_account,
      network_id,
      offer,
      tiktok_pixel_id,
      mbn_id,
    } = submitForm
    if (
      account_id == '' ||
      countries.length == 0 ||
      network_id == null ||
      offer.length == 0 ||
      mbn_id == null
    )
      return false
    else if (network_id == 'Facebook' && facebook_account == null) {
      return false
    } else if (network_id == 'TikTok' && tiktok_pixel_id == null) return false
    else {
      return true
    }
  }
  const handleSubmit = async (e) => {
    enterLoading(1)
    e.preventDefault()
    const submitForm = {
      account_id: selectAccount,
      countries: selectCountry.map((item) => item.key),
      offer: selectVertical.map((item) => item.value),
      callback: selectCallback,
      network_id: selectNetwork.value,
      mbn_id: selectMBN.value,
      user_id: user.email,
      name: campaignName,
      facebook_account: selectFacebookPixel, //in case of network_id = fb send selected account name
      tiktok_pixel_id: selectTiktokPixel?.key, //in case of network_id = tk send internal pixel id
    }
    if (checkData(submitForm)) {
      const result = await NewProjectStore.saveCampaigns(submitForm)
      if (result) {
        setState((prevState) => ({
          ...prevState,
          ['newCampaigns']: result.campaigns_to_create,
        }))
        message.success('The campaign was created successfully')
      } else {
        message.error('Erorr while creating campaign')
      }
    } else {
      message.error('All fields must be valid')
    }
  }

  return (
    <div className="filter-section">
      <Form
        onSubmit={handleSubmit}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        name="basic"
        layout="vertical"
        labelCol={{
          span: 20,
        }}
        wrapperCol={{
          span: 50,
        }}
        style={{
          maxWidth: 1000,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Row gutter={[16, 10]}>
          <Col>
            <Row>
              <Title level={3}>Create a New Campaign</Title>
            </Row>
            <Row style={{ marginBottom: 10 }}></Row>

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Select tonic account"
                  options={accountOptions}
                  value={selectAccount}
                  onChange={handleTonicAccount}
                  loading={isNewProjectLoading}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Select country"
                  options={countryOptions}
                  value={selectCountry}
                  onChange={handleTonicCountry}
                  loading={isNewProjectLoading}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Select vertical"
                  options={verticalOptions}
                  value={selectVertical}
                  onChange={handleTonicVertical}
                  loading={false}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Select network"
                  options={networkDB}
                  value={selectNetwork}
                  onChange={handleNetwork}
                  loading={isNewProjectLoading}
                />
              )}
            </Form.Item>

            {showFacebookAccountList && (
              <Form.Item>
                {!isDetail && (
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    maxTagCount="responsive"
                    placeholder="Select facebook account pixel"
                    options={facebookPixelOptions}
                    onChange={handleFacebookAccount}
                    loading={isNewProjectLoading}
                  />
                )}
              </Form.Item>
            )}

            {showTiktokAccountList && (
              <Form.Item>
                {!isDetail && (
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    maxTagCount="responsive"
                    placeholder="Select tiktok account pixel"
                    options={tiktokPixelOptions}
                    onChange={handleTiktokPixel}
                    loading={isNewProjectLoading}
                  />
                )}
              </Form.Item>
            )}

            {showTiktokAccountList && (
              <Form.Item>
                <Button
                  icon={<PlusOutlined />}
                  onClick={handleShowCreateTiktokPixel}
                >
                  Setup new Tiktok pixel
                </Button>
              </Form.Item>
            )}

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Select Callback"
                  options={tonicCallbackDB}
                  value={selectCallback}
                  onChange={handleTonicCallback}
                  loading={isNewProjectLoading}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  maxTagCount="responsive"
                  placeholder="Media buyer name"
                  options={MBNOptions}
                  value={selectMBN}
                  onChange={handleTonicMBN}
                  loading={isNewProjectLoading}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <Input
                  allowClear
                  type="text"
                  placeholder="Free Text"
                  value={inputFreeText}
                  onChange={(e) => handleFreeText(e.target.value)}
                />
              )}
            </Form.Item>

            <Form.Item>
              {!isDetail && (
                <TextArea
                  style={{ height: 55, resize: 'none' }}
                  type="text"
                  placeholder="Campaign name"
                  value={campaignName}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      ['campaignName']: e.target.value,
                    }))
                  }
                />
              )}
            </Form.Item>

            <Row>
              <Col>
                <Button
                  htmlType="submit"
                  icon={<RocketOutlined />}
                  loading={loadings[1]}
                  onClick={handleSubmit}
                >
                  Create Campaign
                </Button>
              </Col>
              <Col style={{ marginLeft: 7 }}>
                <Button
                  type="button"
                  danger
                  ghost
                  icon={<ClearOutlined />}
                  // loading={loading}
                  onClick={clearState}
                >
                  Reset Form
                </Button>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Form.Item>
            {showTiktokPixelWizard && (
              <TiktokPixel
                onCreationSuccess={() =>
                  handleNetwork('TikTok', {
                    key: 'tk',
                    value: 'TikTok',
                  })
                }
              />
            )}
          </Form.Item>
        </Row>
      </Form>

      <Row gutter={[16, 10]}>
        <Col span={24}>
          <List
            header={
              <div>
                <u>Campaigns that have been created</u>:
              </div>
            }
            itemLayout="horizontal"
            dataSource={newCampaigns}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.name} description="" />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  )
}

export default observer(NewFilter)
