import { Col, Row, Table, Input, Button, Space, Image, Switch } from 'antd'
import { blue } from '@ant-design/colors'
import { ReadOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import { CampaignsStore } from '../../../stores'
import {
  localStorage,
  normalizationUrl,
  sortByNumber,
  sortByString,
} from '../../../utils'
import columns from './columns'
import ColumnCustomizeModal from '../ColumnCustomizeModal'
import { EditableRow, EditableCell } from '../../../components'

const { Column } = Table
const { Search } = Input

function CampaignDetailTable() {
  const { search } = normalizationUrl()
  const [selectedColumn, setSelectedColumn] = useState(
    localStorage.getObject('campaignDetail.customField') || []
  )
  const [showColumnModal, setShowColumnModal] = useState(false)
  const [listItems, setListItems] = useState([])
  const [query, setQuery] = useState('')
  const {
    filter,
    campaignType,
    campaignId,
    campaignDetailList,
    resourceDetailTableState,
    resourceTableState,
    updateCampaignById,
    fetchCampaignDetailResource,
  } = CampaignsStore
  const isWidget = campaignType === 'widget'
  const isContent = campaignType === 'content'
  const isSection = campaignType === 'section'

  useEffect(() => {
    setQuery('')
    // eslint-disable-next-line
  }, [resourceDetailTableState])

  useEffect(() => {
    setListItems(campaignDetailList)
    // eslint-disable-next-line
  }, [campaignDetailList])

  useEffect(() => {
    if (query) {
      setListItems(
        campaignDetailList.filter((item) => item.name.includes(query))
      )
    } else {
      setListItems(campaignDetailList)
    }
    // eslint-disable-next-line
  }, [query])

  const onSearch = ({ target: { value } }) => setQuery(value)

  const getCampaignTypeURL = (type) => {
    return `/campaigns/${type}/${campaignId}?${search}`
  }

  const handleChangeColumn = ({ selected }) => {
    setShowColumnModal(false)
    setSelectedColumn(selected)
    localStorage.setObject('campaignDetail.customField', selected)
  }

  const handleClickEnable = async (data) => {
    await updateCampaignById({
      body: {
        field: 'enabled',
        level: 'widget',
        sub_item_id: data.site_id,
        old_value: !!data.enabled,
        new_value: data.checked,
      },
    })
    await fetchCampaignDetailResource()
  }

  const createOnCellObject = (record, columnProps) => {
    return {
      record,
      editable: true,
      dataIndex: columnProps.dataIndex,
      title: columnProps.title,
      handleSave: async (data) => {
        await updateCampaignById({
          body: {
            field: columnProps.dataIndex,
            campaign_id: data.campaignid,
            old_value: +record[columnProps.dataIndex],
            new_value: +data[columnProps.dataIndex],
          },
        })
        await fetchCampaignDetailResource()
      },
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={18}>
          <Space>
            <Button type={isWidget ? 'primary' : ''}>
              <Link to={getCampaignTypeURL('widget')}>Widgets</Link>
            </Button>
            <Button type={isContent ? 'primary' : ''}>
              <Link to={getCampaignTypeURL('content')}>Contents</Link>
            </Button>
            <Button type={isSection ? 'primary' : ''}>
              <Link to={getCampaignTypeURL('section')}>Sections</Link>
            </Button>
          </Space>
        </Col>

        <Col span={6} align="right">
          <Space>
            <Search
              allowClear
              placeholder="Search by name"
              value={query}
              onChange={onSearch}
              style={{ width: 250 }}
            />

            <Button
              icon={<ReadOutlined />}
              onClick={() => setShowColumnModal(true)}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Table
            size="small"
            dataSource={listItems}
            components={components}
            loading={
              resourceDetailTableState === 'pending' ||
              resourceTableState === 'pending'
            }
            scroll={{
              x: 1500,
              y: 500,
            }}
            pagination={{
              defaultPageSize: 50,
            }}
          >
            {filter.network === 'taboola' && (isWidget || isContent) && (
              <Column
                width={100}
                title="Enable"
                dataIndex="enabled"
                fixed="left"
                render={(item, row) => (
                  <Switch
                    checked={item}
                    onChange={(checked) =>
                      handleClickEnable({ checked, ...row })
                    }
                  />
                )}
              />
            )}

            {isContent && (
              <Column
                width={80}
                title=""
                dataIndex="image_url"
                render={(item) => <Image src={item} />}
                fixed="left"
              />
            )}

            {(isWidget || isSection) && (
              <Column
                ellipsis
                width={300}
                title="Name"
                dataIndex="site"
                sorter={(a, b) => sortByString(a, b, 'site')}
                fixed="left"
              />
            )}

            {isContent && (
              <Column
                ellipsis
                width={300}
                title="Name"
                dataIndex="title"
                sorter={(a, b) => sortByString(a, b, 'title')}
                fixed="left"
              />
            )}

            <Column ellipsis width={200} title="WidgetID" dataIndex="site_id" />

            <Column
              width={120}
              title="Cost"
              dataIndex="cost"
              render={(item) => (
                <span style={{ color: blue[4] }}>
                  {currency(item).format()}
                </span>
              )}
              sorter={(a, b) => sortByNumber(a, b, 'cost')}
              defaultSortOrder="descend"
            />

            {selectedColumn.map((item) => {
              const columnProps =
                columns.find((i) => i.dataIndex === item.dataIndex) || {}

              if (columnProps.editable && filter.network === 'taboola') {
                columnProps.onCell = (record) => {
                  return createOnCellObject(record, columnProps)
                }
              }

              return <Column {...columnProps} key={item.dataIndex} />
            })}
          </Table>
        </Col>
      </Row>

      <ColumnCustomizeModal
        visible={showColumnModal}
        onCancel={() => setShowColumnModal(false)}
        onSubmit={handleChangeColumn}
        items={{
          visibility: columns.filter((item) => {
            return !selectedColumn.find((i) => i.dataIndex === item.dataIndex)
          }),
          selected: selectedColumn,
        }}
      />
    </>
  )
}

export default observer(CampaignDetailTable)
