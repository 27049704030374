import { localStorage } from '../index'

export function createFetchHeaders(options = {}) {
  let headers = new Headers({})

  if (options.body instanceof FormData) {
    headers.set('Accept', '*/*')
  } else {
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
  }

  if (options.headers) {
    for (let key in options.headers) {
      headers.set(key, options.headers[key])
    }
  }

  const token = localStorage.get('authentication')
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return headers
}

export async function fetchUtil(url, options = {}) {
  if (process.env.NODE_ENV === 'development') {
    document.cookie = 'XDEBUG_SESSION=PHPSTORM'
  }

  const requestURL = url.includes('https://')
    ? url
    : process.env.REACT_APP_API_URL + url

  console.log(requestURL)
  options.headers = createFetchHeaders(options)
  try {
    const response = await fetch(requestURL, {
      ...options,
      //untill we'll add auth
      //credentials: 'include',
    })
    // console.log(response);
    const { status } = response
    if (status == 205) {
      return
    }
    const body = await response.json()
    if (status < 200 || status >= 300) {
      const formattedError = JSON.stringify({ ...body, status }, null, 2)
      console.error(formattedError)
      throw formattedError
    }
    return { data: body }
  } catch (error) {
    console.log('error fetchUtill')
    console.log(error)
    return error
  }
}
