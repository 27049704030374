import { observer } from 'mobx-react-lite'
import { useAuth0 } from '@auth0/auth0-react'

function HomeContent() {
  const { isAuthenticated } = useAuth0()
  return isAuthenticated ? (
    <h1>Home content coming soon...</h1>
  ) : (
    <h1>Please login to start using Application.</h1>
  )
}

export default observer(HomeContent)
