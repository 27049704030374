import React from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import { Typography } from 'antd'
import { sortByNumber, toLocaleString } from '../../../utils'

const { Text } = Typography

export default [
  {
    id: 'avg_cpc',
    width: 120,
    title: 'Avg. CPC',
    dataIndex: 'avg_cpc',
    render: (item) => currency(item).format(),
    sorter: (a, b) => sortByNumber(a, b, 'avg_cpc'),
  },
  {
    id: 'ctr',
    width: 120,
    title: 'CTR',
    dataIndex: 'ctr',
    render: (item) => (
      <span style={{ color: blue[4] }}>{currency(item).format()}</span>
    ),
    sorter: (a, b) => sortByNumber(a, b, 'ctr'),
  },
  {
    id: 'clicks',
    width: 120,
    title: 'Clicks',
    dataIndex: 'clicks',
    sorter: (a, b) => sortByNumber(a, b, 'clicks'),
    render: (item) => toLocaleString(item),
  },
  {
    id: 'conversions',
    width: 120,
    title: 'Conversions',
    dataIndex: 'conversions',
    sorter: (a, b) => sortByNumber(a, b, 'conversions'),
  },
  {
    id: 'cpa',
    width: 120,
    title: 'CPA',
    dataIndex: 'cpa',
    sorter: (a, b) => sortByNumber(a, b, 'cpa'),
  },
  {
    id: 'impression',
    width: 120,
    title: 'Impressions',
    dataIndex: 'impression',
    sorter: (a, b) => sortByNumber(a, b, 'impression'),
  },
  {
    id: 'revenue',
    width: 120,
    title: 'Revenue',
    dataIndex: 'revenue',
    sorter: (a, b) => sortByNumber(a, b, 'revenue'),
    render: (item) => currency(item).format(),
  },
  {
    id: 'net_profit',
    width: 120,
    title: 'NET Profit',
    dataIndex: 'net_profit',
    sorter: (a, b) => sortByNumber(a, b, 'net_profit'),
    render: (item) => (
      <Text type={item >= 0 ? 'success' : 'danger'}>
        {currency(item).format()}
      </Text>
    ),
  },
  {
    id: 'roi',
    width: 120,
    title: 'ROI',
    dataIndex: 'roi',
    sorter: (a, b) => sortByNumber(a, b, 'roi'),
    render: (item) => toLocaleString(item),
  },
  {
    id: 'created_time',
    width: 120,
    title: 'Created time',
    dataIndex: 'created_time',
  },
  {
    id: 'cpc',
    width: 120,
    title: 'CPC',
    dataIndex: 'cpc',
    render: (item) => (
      <span style={{ color: blue[4] }}>
        {currency(item, { precision: 3 }).format()}
      </span>
    ),
    sorter: (a, b) => sortByNumber(a, b, 'cpc'),
    editable: true,
  },
]
