import { Layout as AntLayout, Typography } from 'antd'
import { Sidebar, useGlobalData } from '..'
import { useAuth0 } from '@auth0/auth0-react'

const { Content } = AntLayout
const { Title } = Typography

export default function Layout(props) {
  const { children } = props
  const { roles } = useGlobalData()
  const { isAuthenticated, isLoading } = useAuth0()

  const Unauthorized = (
    <div>
      <Title level={3} type="danger">
        401 UNAUTHORIZED
      </Title>
      <span>Access is allowed only for registered users</span>
    </div>
  )

  return (
    <AntLayout>
      <Sidebar />
      <AntLayout>
        <Content className="site-layout">
          <div className="site-layout--content">
            {!isLoading && isAuthenticated && roles.length === 0
              ? Unauthorized
              : children}
          </div>
        </Content>
      </AntLayout>
    </AntLayout>
  )
}
