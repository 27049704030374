import qs from 'qs'
import { fetch } from '../utils'

export default {
  async getTotalData(options) {
    const params = qs.stringify(options)
    return fetch(`/admin/stats?${params}`)
  },
  async getTotalDataPerNetwork(options) {
    const params = qs.stringify(options)
    return fetch(`/admin/stats?${params}`)
  },
  async getDrillDownDataPerNetwork(options) {
    const params = qs.stringify(options)
    return fetch(`/admin/stats?${params}`)
  },
  async getDataPerMediaBuyer(options) {
    const params = qs.stringify(options)
    return fetch(`/admin/stats?${params}`)
  },
  async getisAdmin() {
    return fetch(`/getuserrole`)
  },
}
