import qs from 'qs'
import { fetch } from '../utils'

export default {
  async getTotal(options = {}) {
    const params = qs.stringify(options, { arrayFormat: 'comma' })
    return fetch(`/tonic/total?${params}`)
  },

  async getCampaignStats(options = {}) {
    const params = qs.stringify(options, { arrayFormat: 'comma' })
    return fetch(`/tonic/campaigns?${params}`)
  },

  // eslint-disable-next-line
  async getCampaignStatsById(options) {
    const params = qs.stringify(options)
    return fetch(`/tonic/total?${params}`)
  },

  async getWidgetsById({ id, ...options }) {
    const params = qs.stringify(options)
    return fetch(`/tonic/campaigns/${id}/publishers?${params}`)
  },

  async getWidgetsByIdV2({ id, ...options }) {
    const params = qs.stringify({
      ...options,
      campaignID: id,
    })
    return fetch(`/tonic/campaigns/widgets?${params}`)
  },

  async getSectionsById({ id, ...options }) {
    const params = qs.stringify(options)
    return fetch(`/tonic/campaigns/${id}/sections?${params}`)
  },

  async updateCampaignById({ body, network }) {
    console.log('inside updateCampaignById')
    const url = `/edit/campaign/${network}`
    // eslint-disable-next-line
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
      })
      if (typeof response === 'string' && response.includes(500)) {
        // console.log('error updateCampaignById campaigns')
        throw response
      }
      console.log('response!', response)
      return response
    } catch (error) {
      throw error
    }
  },

  async getCampaignDrillDown({ options }) {
    const params = qs.stringify(options)
    return fetch(`/tonic/campaign_drilldown?${params}`)
  },

  async getCampaignKeywordDrillDown({ options }) {
    const params = qs.stringify(options)
    return fetch(`/tonic/campaign_keyword_drilldown?${params}`)
  },

  async getAdgroup(options) {
    const params = qs.stringify(options)
    return fetch(`/tonic/adgroups?${params}`)
  },

  async getAds(options) {
    const params = qs.stringify(options)
    return fetch(`/tonic/ads?${params}`)
  },

  async getIsFinal(options) {
    const params = qs.stringify(options)
    return fetch(`/tonic/isfinal?${params}`)
  },
}
