import { Col, Row, Table, Input, Button, Space, Image } from 'antd'
import { blue } from '@ant-design/colors'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import { ReportsStore } from '../../../stores'
import { normalizationUrl, sortByNumber, sortByString } from '../../../utils'

const { Column } = Table
const { Search } = Input

function ReportDetailTable() {
  const { search } = normalizationUrl()
  const [listItems, setListItems] = useState([])
  const [query, setQuery] = useState('')
  const { reportType, reportId, reportDetailList, resourceDetailTableState } =
    ReportsStore
  const isWidget = reportType === 'widget'
  const isContent = reportType === 'content'
  const isSection = reportType === 'section'

  useEffect(() => {
    setQuery('')
    // eslint-disable-next-line
  }, [resourceDetailTableState])

  useEffect(() => {
    setListItems(reportDetailList)
    // eslint-disable-next-line
  }, [reportDetailList])

  useEffect(() => {
    if (query) {
      setListItems(reportDetailList.filter((item) => item.name.includes(query)))
    } else {
      setListItems(reportDetailList)
    }
    // eslint-disable-next-line
  }, [query])

  const onSearch = ({ target: { value } }) => setQuery(value)

  const getReportTypeURL = (type) => {
    return `/reports/${type}/${reportId}?${search}`
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={18}>
        <Space>
          <Button type={isWidget ? 'primary' : ''}>
            <Link to={getReportTypeURL('widget')}>Widgets</Link>
          </Button>
          <Button type={isContent ? 'primary' : ''}>
            <Link to={getReportTypeURL('content')}>Contents</Link>
          </Button>
          <Button type={isSection ? 'primary' : ''}>
            <Link to={getReportTypeURL('section')}>Sections</Link>
          </Button>
        </Space>
      </Col>

      <Col span={6} align="right">
        <Search
          allowClear
          placeholder="Search by name"
          value={query}
          onChange={onSearch}
          style={{ width: 250 }}
        />
      </Col>

      <Col span={24}>
        <Table
          size="small"
          dataSource={listItems}
          loading={resourceDetailTableState === 'pending'}
          scroll={{
            x: 1500,
            y: 500,
          }}
          pagination={{
            defaultPageSize: 50,
          }}
        >
          {isContent && (
            <Column
              width={80}
              title=""
              dataIndex="imageurl"
              render={(item) => <Image src={item} />}
              fixed="left"
            />
          )}

          {(isWidget || isSection) && (
            <Column
              ellipsis
              width={300}
              title="Name"
              dataIndex="name"
              sorter={(a, b) => sortByString(a, b, 'name')}
              fixed="left"
            />
          )}

          {isContent && (
            <Column
              ellipsis
              width={300}
              title="Name"
              dataIndex="title"
              sorter={(a, b) => sortByString(a, b, 'title')}
              fixed="left"
            />
          )}

          <Column ellipsis width={200} title="WidgetID" dataIndex="id" />

          <Column
            width={120}
            title="Avg. CPC"
            dataIndex="avg_cpc"
            render={(item) => currency(item).format()}
            sorter={(a, b) => sortByNumber(a, b, 'avg_cpc')}
          />

          <Column
            width={120}
            title="Cost"
            dataIndex="cost"
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'cost')}
            defaultSortOrder="descend"
          />

          <Column
            width={120}
            title="CTR"
            dataIndex="ctr"
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'ctr')}
          />

          <Column
            width={120}
            title="Clicks"
            dataIndex="clicks"
            sorter={(a, b) => sortByNumber(a, b, 'clicks')}
          />

          <Column
            width={120}
            title="Conversions"
            dataIndex="conversions"
            sorter={(a, b) => sortByNumber(a, b, 'conversions')}
          />

          <Column
            width={120}
            title="CPA"
            dataIndex="cpa"
            sorter={(a, b) => sortByNumber(a, b, 'cpa')}
          />

          <Column
            width={120}
            title="Impressions"
            dataIndex="impression"
            sorter={(a, b) => sortByNumber(a, b, 'impression')}
          />
          <Column
            title="Revenue"
            dataIndex="revenue"
            sorter={(a, b) => sortByNumber(a, b, 'revenue')}
          />
        </Table>
      </Col>
    </Row>
  )
}

export default observer(ReportDetailTable)
