export const TRAFFIC_SOURCE_TYPES = [
  {
    label: 'All Networks',
    value: 'All',
  },
  {
    label: 'Facebook',
    value: 'facebook',
    image: '/assets/images/facebook.png',
  },
  {
    label: 'Tiktok',
    value: 'tiktok',
    image: '/assets/images/tiktok.png',
  },
  {
    label: 'Outbrain',
    value: 'outbrain',
    image: '/assets/images/outbrain.png',
  },
  {
    label: 'Taboola',
    value: 'taboola',
    image: '/assets/images/taboola.png',
  },
]
