import HomeContent from './containers/HomeContent'
import Admin from './containers/Admin'
import Campaigns from './containers/Campaigns'
import CampaignsDetail from './containers/Campaigns/detail'
import Reports from './containers/Reports'
import ReportsDetail from './containers/Reports/detail'
import Tiktok from './containers/Tiktok'
import CampaignsManagement from './containers/CampaignsManagement'
import NewProject from './containers/NewProject'

export default [
  {
    path: '/',
    exact: true,
    component: HomeContent,
  },
  {
    path: '/admin',
    exact: true,
    component: Admin,
    auth: true,
  },
  {
    path: '/campaigns',
    exact: true,
    component: Campaigns,
    auth: true,
  },
  {
    path: '/campaigns/:campaignType/:id',
    exact: true,
    component: CampaignsDetail,
    auth: true,
  },
  {
    path: '/reports',
    exact: true,
    component: Reports,
    auth: true,
  },
  {
    path: '/reports/:reportType/:id',
    exact: true,
    component: ReportsDetail,
    auth: true,
  },
  {
    path: '/tiktok',
    exact: true,
    component: Tiktok,
  },
  {
    path: '/campaignsManagement',
    exact: true,
    component: CampaignsManagement,
    auth: true,
  },
  {
    path: '/newProject',
    exact: true,
    component: NewProject,
    auth: true,
  },
]
