import moment from 'moment'
import { DatePicker, Image, Radio, Select, Space } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AccountsStore } from '../../stores'
import { parseDateValue, localStorage } from '../../utils'
import { useGlobalData } from '../../components'
import { TRAFFIC_SOURCE_TYPES } from '../../assets/TrafficSourceTypes'

const { RangePicker } = DatePicker

const FILTER_OPTIONS = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'week' },
]

function Filter(props) {
  const { networks } = useGlobalData()
  const {
    filter,
    onChangeFilter,
    isDetail,
    isAdmin,
    isMediaBuyer,
    setCustomNetwork,
    setRangeDateChanged,
  } = props
  const [selectedAccount, setSelectedAccount] = useState()
  const [dateRange, setDateRange] = useState(null)
  const [accountOptions, setAccountOptions] = useState(null)
  const { accounts, state } = AccountsStore
  const isAccountLoading = state === 'pending'
  const trafficSourceTypes = TRAFFIC_SOURCE_TYPES.filter(
    (item) => networks.includes(item.value) || item.value === 'All'
  )

  useEffect(() => {
    const accountIds = filter.account_id || []
    const _selectedAccount = accountIds.map((id) =>
      accountOptions.find((item) => item?.key == id)
    )
    setSelectedAccount(_selectedAccount)
    if (filter.from && filter.to) {
      setDateRange([moment(filter.from), moment(filter.to)])
    }
    // eslint-disable-next-line
  }, [filter])

  // useEffect(() => {
  //   // update adgroup on date change
  //   console.log('USE EFFECT SELECTED TAB')
  //   console.log(selectedTab)
  //   if (selectedTab === 'adgroups') {
  //     reRenderAdGroups()
  //   }
  // }, [filter])

  // const reRenderAdGroups=()=>{

  //   const adGroupParams = getAdGroupListParams()
  //   if (adGroupParams) {
  //     console.log("RE RENDER");
  //     console.log({ adGroupParams })
  //     let newObject = 'campaignID='
  //     newObject = Object.values(adGroupParams)
  //       .map((obj) => obj.campaignid)
  //       .join(',')
  //     fetchAdgroupList({ campaignID: newObject })
  //   }
  // }

  useMemo(() => {
    const _accountOptions = accounts.map((item) => ({
      key: item.orgID,
      value: item.name,
    }))
    setAccountOptions(_accountOptions)

    const accountIds = filter.account_id || []
    const _selectedAccount = accountIds.map((id) =>
      _accountOptions.find((item) => item.key == id)
    )
    setSelectedAccount(_selectedAccount)
    // eslint-disable-next-line
  }, [accounts])

  const handleChangeDateType = ({ target: { value } }) => {
    let { from, to } = parseDateValue({ date: value })
    isMediaBuyer ? setRangeDateChanged(true) : ''
    onChangeFilter({
      account_id: filter.account_id ? filter.account_id : undefined,
      network: filter.network ? filter.network : undefined,
      tab: filter.tab ? filter.tab : undefined,
      date: value,
      from,
      to,
    })
  }

  const handleChangeDateRange = (val) => {
    isMediaBuyer ? setRangeDateChanged(true) : ''
    if (val) {
      setDateRange(val)
    } else {
      setDateRange()
      onChangeFilter({
        account_id: filter.account_id ? filter.account_id : undefined,
        network: filter.network ? filter.network : undefined,
        date: 'today',
      })
    }
  }

  const handleChangeAccount = (val, data) => {
    if (data.length > 0) {
      onChangeFilter({
        ...filter,
        account_id: data.map((i) => i.key),
      })
    } else {
      const { account_id, ...rest } = filter // eslint-disable-line
      onChangeFilter(rest)
    }
  }

  const handleChangeSourceType = (val) => {
    if (val) {
      if (isMediaBuyer) {
        const changedMediaFilter = localStorage.getObject('admin.filter')
        changedMediaFilter.network = val
        localStorage.setObject('admin.filter', changedMediaFilter)
        setCustomNetwork(val)
      }

      onChangeFilter({
        ...filter,
        network: val,
        account_id: undefined,
        tab: val === 'tiktok' ? 'campaign' : undefined,
      })
    } else {
      const { network, account, ...rest } = filter // eslint-disable-line
      onChangeFilter(rest)
    }
  }

  const handleDateRangeOnOpenChange = (open) => {
    isMediaBuyer ? setRangeDateChanged(true) : ''
    const [from, to] = dateRange
    // INFO: check if the change cause by antD picker closing and if filter have changed
    if (!open) {
      onChangeFilter({
        account_id: filter.account_id ?? undefined,
        network: filter.network ?? undefined,
        date: 'custom',
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      })
    }
  }

  return (
    <div
      className={isMediaBuyer ? 'mediaBuyer-filter-section' : 'filter-section'}
    >
      <Space>
        <Radio.Group
          options={FILTER_OPTIONS}
          onChange={handleChangeDateType}
          value={filter.date || 'today'}
          optionType="button"
        />

        <RangePicker
          value={dateRange}
          onChange={handleChangeDateRange}
          onOpenChange={handleDateRangeOnOpenChange}
        />

        {!isDetail && !isAdmin && (
          <Select
            style={{ width: 250 }}
            placeholder="Select traffic source type"
            optionLabelProp="label"
            onChange={handleChangeSourceType}
            value={filter.network || ''}
          >
            {trafficSourceTypes.map((item) => (
              <Select.Option label={item.label} key={item.value}>
                <Space>
                  {item.image && (
                    <Image
                      src={item.image}
                      preview={false}
                      height={20}
                      width={20}
                    />
                  )}
                  {item.label}
                </Space>
              </Select.Option>
            ))}
          </Select>
        )}

        {!isDetail && !isAdmin && !isMediaBuyer && (
          <div style={{ width: 290 }}>
            <Select
              showSearch
              allowClear
              autoClearSearchValue={false}
              mode="multiple"
              maxTagCount="responsive"
              placeholder="Select an account"
              options={accountOptions}
              style={{ width: '100%' }}
              value={selectedAccount}
              onChange={handleChangeAccount}
              loading={isAccountLoading}
            />
          </div>
        )}
      </Space>
    </div>
  )
}

export default observer(Filter)
