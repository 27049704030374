import { Card, Col, Divider, Row, Space, Spin, Typography } from 'antd'
import qs from 'qs'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import get from 'lodash/get'
import './reports.scss'
import { AccountsStore, ReportsStore } from '../../stores'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ReportDetailTable from './ReportDetailTable'

import Filter from './Filter'

const { Title, Text } = Typography

function ReportsDetail(props) {
  const {
    match: { params },
  } = props
  const {
    setReportId,
    setReportType,
    state,
    reportDetailStats,
    filter,
    setDetailFilter,
    fetchReportDetailResource,
    reportDetail,
  } = ReportsStore
  const { accounts } = AccountsStore
  const { id, reportType } = params
  const isLoading = state === 'pending'

  useEffect(() => {
    setReportId(id)
    setReportType(reportType)
    setDetailFilter(qs.parse(props.location.search.slice(1)))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setReportType(reportType)
    fetchReportDetailResource()
    // eslint-disable-next-line
  }, [reportType])

  return (
    <div className="report-page">
      <Title level={5} style={{ margin: 0 }}>
        <Text type="secondary">Report:</Text> {get(reportDetail, '[0].name')}
      </Title>

      <Divider />

      <Filter
        {...props}
        accounts={accounts}
        filter={filter}
        onChangeFilter={setDetailFilter}
      />

      <Row gutter={[16, 64]}>
        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="danger">
                Total Cost
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(reportDetailStats.cost).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="success">
                Total Revenue
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(reportDetailStats.revenue).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} type="warning">
                Total Net
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(reportDetailStats.net).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="cart-title">
              <Title level={5} style={{ color: blue[4] }}>
                Total ROI
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>
                  {currency(reportDetailStats.roi).format()}
                </Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <ReportDetailTable {...props} />
        </Col>
      </Row>
    </div>
  )
}

export default observer(ReportsDetail)
