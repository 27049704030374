import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Layout, ProtectedRoute } from '../components'
import routes from '../routes'
import { observer } from 'mobx-react-lite'

function App() {
  useEffect(() => {
    const isDev = window.location.href.includes('dev.tarzomedia.com')
    const isLocal = process.env.NODE_ENV === 'development'

    if (isDev || isLocal) {
      const icon = document.head.querySelector('link[rel="icon"]')
      icon.href = '/favicon-dev.ico'
    }
  }, [])

  return (
    <Switch>
      {routes.map((route, index) => {
        const RouteComponent = route.auth ? ProtectedRoute : Route

        return (
          <RouteComponent
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => (
              <Layout>
                <route.component {...props} />
              </Layout>
            )}
          />
        )
      })}
      <Redirect to="/" />
    </Switch>
  )
}

export default observer(App)
