import { Button, Col, Modal, Radio, Row, Table, Space, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import moment from 'moment'
import { CopyOutlined, ReadOutlined } from '@ant-design/icons'
import { localStorage, sortByDate, sortByNumber } from '../../utils'
import parseFilterValue from '../../utils/parseDateValue'
import { CampaignsManagementStore } from '../../stores'
import columns from './CampaignsManagementTable/columns'
import ColumnCustomizeModal from './ColumnCustomizeModal'

const { Column } = Table

const FILTER_OPTIONS = [
  { label: 'Last 7 days', value: 'week' },
  { label: 'Last 30 days', value: 'month' },
]

function CopyButton({ name }) {
  const [copied, setCopied] = useState(false)

  const handleCopyCampaignName = () => {
    navigator.clipboard.writeText(name)
    setCopied(true)
  }

  return (
    <Tooltip placement="top" title={copied ? 'Copied' : 'Copy to clipboard'}>
      <Button
        icon={<CopyOutlined />}
        size="small"
        onClick={handleCopyCampaignName}
        onBlur={() => setCopied(false)}
      />
    </Tooltip>
  )
}

function DrilldownDataModal(props) {
  const { visible, onCancel, data } = props
  const { filter, fetchDrillDownData } = CampaignsManagementStore
  const [loading, setLoading] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [date, setDate] = useState('week')
  const [selectedColumn, setSelectedColumn] = useState(
    localStorage.getObject('drilldownModal.customField') || []
  )
  const [showColumnModal, setShowColumnModal] = useState(false)

  useEffect(() => {
    setShowColumnModal(false)

    if (visible) {
      fetchData(date)
    } else {
      setDataTable([])
    }
    // eslint-disable-next-line
  }, [visible])

  async function fetchData(dateFilter) {
    const { from, to } = parseFilterValue({ date: dateFilter })
    const campaignID = data?.campaignid

    setLoading(true)
    const resp = await fetchDrillDownData({
      network: filter.network,
      from,
      to,
      campaignID,
    })

    const _dataTable = resp.data.map((item, index) => ({
      ...item,
      key: index,
    }))

    setLoading(false)
    setDataTable(_dataTable)
  }

  const handleChangeDateType = ({ target: { value } }) => {
    setDate(value)
    fetchData(value)
  }

  const handleChangeColumn = ({ selected }) => {
    setShowColumnModal(false)
    setSelectedColumn(selected)
    localStorage.setObject('drilldownModal.customField', selected)
  }

  if (!visible) return null

  return (
    <Modal
      title={
        <Space>
          {data?.name}
          <CopyButton name={data?.name} />
        </Space>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width="80%"
    >
      <Row>
        <Col span={12}>
          <Radio.Group
            options={FILTER_OPTIONS}
            onChange={handleChangeDateType}
            value={date || 'week'}
            optionType="button"
            style={{ marginBottom: 15 }}
          />
        </Col>
        <Col span={12} align="right">
          <Button
            icon={<ReadOutlined />}
            onClick={() => setShowColumnModal(true)}
          />
        </Col>
      </Row>

      <Table
        size="small"
        dataSource={dataTable}
        loading={loading}
        scroll={{
          x: 'max-content', // original: 1500 === 'max-content' === 'fit-content'
          y: 500,
        }}
        pagination={{
          defaultPageSize: 50,
        }}
      >
        <Column
          width={120}
          title="Date"
          dataIndex="date"
          sorter={(a, b) => sortByDate(a, b, 'date')}
          fixed="left"
          render={(item) => moment(item).format('YYYY-MM-DD')}
        />
        <Column
          width={120}
          title="Cost"
          dataIndex="cost"
          render={(item) => (
            <span style={{ color: blue[4] }}>{currency(item).format()}</span>
          )}
          sorter={(a, b) => sortByNumber(a, b, 'cost')}
        />

        {selectedColumn.map((item) => {
          const columnProps =
            columns.find((i) => i.dataIndex === item.dataIndex) || {}

          return <Column {...columnProps} key={item.dataIndex} />
        })}
      </Table>

      <ColumnCustomizeModal
        visible={showColumnModal}
        onCancel={() => setShowColumnModal(false)}
        onSubmit={handleChangeColumn}
        items={{
          visibility: columns.filter((item) => {
            return !selectedColumn.find((i) => i.dataIndex === item.dataIndex)
          }),
          selected: selectedColumn,
        }}
      />
    </Modal>
  )
}

export default observer(DrilldownDataModal)
