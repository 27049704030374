import { Card, Col, Row, Space, Spin, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useCallback } from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import './TotalDataPanel.scss'
import { AdminStore } from '../../../stores'
import { localStorage } from '../../../utils'

const { Title } = Typography

function TotalDataPanel() {
  const [totalCost, setTotalCost] = useState('')
  const [totalRevenue, setTotalRevenue] = useState('')
  const [totalNet, setTotalNet] = useState('')
  const [totalRoi, setTotalRoi] = useState('')
  const { filter, fetchTotalData } = AdminStore
  const [isLoading, setIsLoading] = useState(true)
  const TOTAL_REPORT_NAME = 'revandspendaggregate'

  const getTotalData = useCallback(async () => {
    setIsLoading(true)
    const date = localStorage.getObject('campaign.filter')
    const data = await fetchTotalData({
      from: date.from,
      to: date.to,
      reportName: TOTAL_REPORT_NAME,
    })
    const response = await data.data[0]
    const { spend, revenue, net_profit, roi } = response
    setTotalCost(spend)
    setTotalRevenue(revenue)
    setTotalNet(net_profit)
    setTotalRoi(roi)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    getTotalData()
    // eslint-disable-next-line
  }, [getTotalData])

  return (
    <div className="totalData-panel">
      <Row gutter={[16, 60]}>
        <Col span={6}>
          <Card>
            <Space className="total-title">
              <Title level={5} type="danger">
                Total Cost
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>{currency(totalCost).format()}</Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="total-title">
              <Title level={5} type="success">
                Total Revenue
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5}>{currency(totalRevenue).format()}</Title>
              )}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="total-title">
              <Title level={5} type="warning">
                Total Net
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5} type={totalNet >= 0 ? 'success' : 'danger'}>
                  {currency(totalNet).format()}
                </Title>
              )}{' '}
            </Space>
          </Card>
        </Col>

        <Col span={6}>
          <Card>
            <Space className="total-title">
              <Title level={5} style={{ color: blue[4] }}>
                Total ROI
              </Title>
              {isLoading ? (
                <Spin />
              ) : (
                <Title level={5} type={totalRoi >= 0 ? 'success' : 'danger'}>
                  {Math.round(totalRoi * 100) / 100}%
                </Title>
              )}
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default observer(TotalDataPanel)
