import { Row, Col } from 'antd'
// import qs from 'qs'
import './NewProject.scss'
import { NewProjectStore } from '../../stores' // NewProjectStore
import { observer } from 'mobx-react-lite'
import NewFilter from './NewFilter'
// import { useEffect } from 'react'
// import { useGlobalData } from '../../components'

function NewProject(props) {
  const { setFilter, filter } = NewProjectStore

  // const { networks } = useGlobalData()

  // useEffect(() => {
  //   let _filter = qs.parse(window.location.hash.split('?')[1])

  //   if (_filter.account_id) {
  //     _filter.account_id = _filter.account_id.split(',')
  //   }
  //   _filter = Object.keys(_filter).length > 0 ? _filter : filter
  //   if (networks && networks.length > 0 && !filter.network) {
  //     setFilter({
  //       ..._filter,
  //       network: networks[0],
  //     })
  //   } else {
  //     setFilter(_filter)
  //   }
  //   // eslint-disable-next-line
  // }, [networks])

  return (
    <div className="campaign-page">
      <NewFilter {...props} filter={filter} onChangeFilter={setFilter} />

      <Row gutter={[16, 64]}>
        <Col span={24}></Col>
      </Row>
    </div>
  )
}

export default observer(NewProject)
