import { Col, Row, Table, Tag, Input, Typography } from 'antd'
import { blue } from '@ant-design/colors'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import { ReportsStore } from '../../../stores' //Change to ReportsStore
import {
  localStorage,
  normalizationUrl,
  sortByNumber,
  sortByString,
} from '../../../utils'

const { Text } = Typography
const { Column } = Table
const { Search } = Input
const { CheckableTag } = Tag

const CUSTOM_COLUMNS = [
  {
    width: 120,
    title: 'CPA',
    dataIndex: 'cpa',
    sorter: (a, b) => sortByNumber(a, b, 'cpa'),
  },
  {
    width: 120,
    title: 'Avg CPC',
    dataIndex: 'acpc',
    sorter: (a, b) => sortByNumber(a, b, 'acpc'),
    render: (item) => (
      <span style={{ color: blue[4] }}>{currency(item).format()}</span>
    ),
  },
  {
    width: 120,
    title: 'NET Profit',
    dataIndex: 'net_profit',
    sorter: (a, b) => sortByNumber(a, b, 'net_profit'),
    render: (item) => currency(item).format(),
  },
  {
    width: 120,
    title: 'Clicks',
    dataIndex: 'clicks',
    sorter: (a, b) => sortByNumber(a, b, 'clicks'),
  },
  {
    width: 120,
    title: 'Impressions',
    dataIndex: 'impressions',
    sorter: (a, b) => sortByNumber(a, b, 'impressions'),
    render: (item) => parseInt(item, 10).toLocaleString(),
  },
  {
    width: 120,
    title: 'Conversions',
    dataIndex: 'conversions',
    sorter: (a, b) => sortByNumber(a, b, 'conversions'),
  },
]

function ReportTable() {
  const [selectedColumn, setSelectedColumn] = useState(
    localStorage.getObject('campaign.customField') || []
  )
  const [listItems, setListItems] = useState([])
  const [query, setQuery] = useState('')
  const { campaigns, resourceTableState } = ReportsStore
  const { search } = normalizationUrl()

  useEffect(() => {
    setQuery('')
    // eslint-disable-next-line
  }, [resourceTableState])

  useEffect(() => {
    setListItems(campaigns)
    // eslint-disable-next-line
  }, [campaigns])

  useEffect(() => {
    if (query) {
      setListItems(campaigns.filter((item) => item.name.includes(query)))
    } else {
      setListItems(campaigns)
    }
    // eslint-disable-next-line
  }, [query])

  const onSearch = ({ target: { value } }) => setQuery(value)

  const onToggleColumn = (value) => {
    const _selectedColumn = [...selectedColumn]
    const index = _selectedColumn.findIndex(
      (item) => item.dataIndex === value.dataIndex
    )

    if (index > -1) {
      _selectedColumn.splice(index, 1)
    } else {
      _selectedColumn.push(value)
    }

    setSelectedColumn(_selectedColumn)
    localStorage.setObject('campaign.customField', _selectedColumn)
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={18}>
        <Text style={{ fontSize: 14 }}>Display columns:</Text>&nbsp;
        {CUSTOM_COLUMNS.map((column) => {
          const isSelected = selectedColumn.find(
            (item) => item.dataIndex === column.dataIndex
          )
          return (
            <CheckableTag
              checked={isSelected}
              key={column.dataIndex}
              onChange={() => onToggleColumn(column)}
            >
              {column.title}
            </CheckableTag>
          )
        })}
      </Col>

      <Col span={6} align="right">
        <Search
          allowClear
          placeholder="Search by name"
          value={query}
          onChange={onSearch}
          style={{ width: 250 }}
        />
      </Col>

      <Col span={24}>
        <Table
          size="small"
          dataSource={listItems}
          loading={resourceTableState === 'pending'}
          scroll={{
            x: 1500,
            y: 500,
          }}
          pagination={{
            defaultPageSize: 50,
          }}
        >
          <Column
            ellipsis
            width={300}
            title="Campaign Name"
            dataIndex="name" // change it depending on the datastore source
            sorter={(a, b) => sortByString(a, b, 'campaign Name')}
            render={(item, row) => (
              <Link
                to={`/reports/widget/${row.reportid}${
                  search ? '?' + search : ''
                }`}
              >
                {item}
              </Link>
            )}
            fixed="left"
          />
          <Column
            ellipsis
            width={100}
            title="Delivery"
            dataIndex="delivery" // campaignid --> change it depending on the datastore source
            sorter={(a, b) => sortByString(a, b, 'delivery')}
          />
          <Column
            width={100}
            title="Results"
            dataIndex="results" // enabled --> change it depending on the datastore source
            // render={(item) =>
            //   item ? (
            //     <Tag color="success">Enabled</Tag>
            //   ) : (
            //     <Tag color="red">Disabled</Tag>
            //   )
            // }
          />
          <Column
            width={120}
            title="Cost Per Results"
            dataIndex="" // budget --> change it depending on the datastore source
            // render={(item) => (
            //   <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            // )}
            sorter={(a, b) => sortByNumber(a, b, 'cost Per Results')}
          />
          <Column
            width={120}
            title="Amount Spend"
            dataIndex="" // cpc --> change it depending on the datastore source
            // render={(item) => (
            //   <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            // )}
            sorter={(a, b) => sortByNumber(a, b, 'amount Spend')}
          />
          <Column
            width={60}
            title="CPC"
            dataIndex="cpc" // change it depending on the datastore source
            render={(item) => (
              <span style={{ color: blue[4] }}>{currency(item).format()}</span>
            )}
            sorter={(a, b) => sortByNumber(a, b, 'cpc')}
          />
          <Column
            width={60}
            title="CTR"
            dataIndex="" // cost --> change it depending on the datastore source
            sorter={(a, b) => sortByNumber(a, b, 'CTR')}
            defaultSortOrder="descend"
          />
          <Column
            width={100}
            title="Revenue"
            dataIndex="revenue" // change it depending on the datastore source
            sorter={(a, b) => sortByNumber(a, b, 'revenue')}
            render={(item) => currency(item).format()}
          />
          <Column
            width={100}
            title="Net Profit "
            dataIndex="" // change it depending on the datastore source
            sorter={(a, b) => sortByNumber(a, b, 'net profit ')}
            // render={(item) => currency(item).format()}
          />
          <Column
            width={60}
            title="ROI" // at in percentages --> %
            dataIndex="" // change it depending on the datastore source
            sorter={(a, b) => sortByNumber(a, b, 'roi')}
            // render={(item) => currency(item).format()}
          />
          {selectedColumn.map((item) => (
            <Column {...item} key={item.dataIndex} />
          ))}
        </Table>
      </Col>
    </Row>
  )
}

export default observer(ReportTable)
