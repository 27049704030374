import moment from 'moment'

export default function parseFilterValue({ date, from, to, ...rest }) {
  if (date === 'yesterday') {
    return {
      ...rest,
      from: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    }
  }

  if (date === 'week') {
    return {
      ...rest,
      from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    }
  }

  if (date === 'month') {
    return {
      ...rest,
      from: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    }
  }

  if (date === 'custom') {
    return { ...rest, from, to }
  }

  return {
    ...rest,
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  }
}
