import { makeAutoObservable } from 'mobx'
import api from '../../api'

class AccountsStore {
  accounts = []
  state = 'pending' // 'pending', 'done' or 'error'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchAccounts(network) {
    if (!network) {
      return null
    }

    this.state = 'pending'

    try {
      let {
        data: { data },
      } = yield api.accounts.getAccounts(network)

      this.accounts = data

      this.state = 'done'
    } catch (e) {
      this.state = 'error'
      console.log('Some thing went wrong')
    }
  }
}

export default new AccountsStore()
