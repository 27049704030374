import React from 'react'
import { blue } from '@ant-design/colors'
import currency from 'currency.js'
import { Typography } from 'antd'
import moment from 'moment'
import { sortByNumber, sortByDate, toLocaleString } from '../../../utils'

const { Text } = Typography

export default [
  {
    id: 'budget',
    width: 120,
    title: 'Budget',
    dataIndex: 'budget',
    render: (item) => (
      <span style={{ color: blue[4] }}>
        {item == null
          ? '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0NA'
          : currency(item, { precision: 2 }).format()}
      </span>
    ),
    sorter: (a, b) => sortByNumber(a, b, 'budget'),
    editable: true,
  },
  {
    id: 'cpc',
    width: 120,
    title: 'CPC',
    dataIndex: 'cpc',
    render: (item) => <span>{currency(item, { precision: 3 }).format()}</span>,
    sorter: (a, b) => sortByNumber(a, b, 'cpc'),
  },
  {
    id: 'cpc_original',
    width: 120,
    title: 'CPC Original',
    dataIndex: '',
    render: (item, row) => row.cpc,
    sorter: (a, b) => sortByNumber(a, b, 'cpc'),
    editable: true,
  },
  {
    id: 'revenue',
    width: 120,
    title: 'Revenue',
    dataIndex: 'revenue',
    sorter: (a, b) => sortByNumber(a, b, 'revenue'),
    render: (item) => currency(item).format(),
  },
  {
    id: 'cpa',
    width: 120,
    title: 'CPA',
    dataIndex: 'cpa',
    sorter: (a, b) => sortByNumber(a, b, 'cpa'),
  },
  {
    id: 'acpc',
    width: 120,
    title: 'Avg CPC',
    dataIndex: 'acpc',
    sorter: (a, b) => sortByNumber(a, b, 'acpc'),
    render: (item) => (
      <span style={{ color: blue[4] }}>{currency(item).format()}</span>
    ),
  },
  {
    id: 'net_profit',
    width: 120,
    title: 'NET Profit',
    dataIndex: 'net_profit',
    sorter: (a, b) => sortByNumber(a, b, 'net_profit'),
    render: (item) => (
      <Text type={item >= 0 ? 'success' : 'danger'}>
        {currency(item).format()}
      </Text>
    ),
  },
  {
    id: 'clicks',
    width: 120,
    title: 'Clicks',
    dataIndex: 'clicks',
    sorter: (a, b) => sortByNumber(a, b, 'clicks'),
    render: (item) => toLocaleString(item),
  },
  {
    id: 'impressions',
    width: 120,
    title: 'Impressions',
    dataIndex: 'impressions',
    sorter: (a, b) => sortByNumber(a, b, 'impressions'),
    render: (item) => toLocaleString(parseInt(item, 10)),
  },
  {
    id: 'conversions',
    width: 120,
    title: 'Conversions',
    dataIndex: 'conversions',
    sorter: (a, b) => sortByNumber(a, b, 'conversions'),
  },
  {
    id: 'roi',
    width: 120,
    title: 'ROI',
    dataIndex: 'roi',
    sorter: (a, b) => sortByNumber(a, b, 'roi'),
    render: (item) => {
      const value = Math.round(item * 100) || 0
      return (
        <Text type={value >= 100 ? 'success' : 'danger'}>
          {!!item && `${toLocaleString(value)}%`}
        </Text>
      )
    },
  },
  {
    id: 'created_time',
    width: 120,
    title: 'Created time',
    dataIndex: 'created_time',
    sorter: (a, b) => sortByDate(a, b, 'created_time'),
    render: (item) => moment(item).format('YYYY-MM-DD'),
  },
  // {
  //   id: 'daily_budget',
  //   width: 120,
  //   title: 'Daily Budget',
  //   dataIndex: 'daily_budget',
  //   sorter: (a, b) => sortByNumber(a, b, 'daily_budget'),
  //   render: (item) => (
  //     <span style={{ color: blue[4] }}>{currency(item).format()}</span>
  //   ),
  //   editable: true,
  // },
  {
    id: 'account_id',
    width: 120,
    title: 'Account ID',
    dataIndex: 'account_id',
    ellipsis: true,
  },
  {
    id: 'account_name',
    title: 'Account Name',
    dataIndex: 'account_name',
    ellipsis: true,
  },
  {
    id: 'ctr',
    width: 120,
    title: 'CTR',
    dataIndex: 'ctr',
    sorter: (a, b) => sortByNumber(a, b, 'ctr'),
    render: (item) => {
      const value = Math.round(item * 100) / 100 || 0
      return `${toLocaleString(value)}%`
    },
  },
  {
    id: 'tr_clicks',
    width: 120,
    title: 'TR Clicks',
    dataIndex: 'tr_clicks',
  },
  {
    id: 'rpc',
    width: 120,
    title: 'RPC',
    dataIndex: 'rpc',
    render: (item) => currency(item, { precision: 3 }).format(),
  },
  {
    id: 'cr_percentage',
    width: 120,
    title: 'CR%',
    dataIndex: 'cr_percentage',
    render: (item, row) => {
      const clicks = (+row.tr_clicks / +row.clicks) * 100
      return clicks.toFixed(2) + '%'
    },
  },
]
